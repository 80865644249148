$grey: #868686;
$green: #64b246;
$primary: #64b246;
$white: #ffffff;
$bg_grey: #f2f2f2;
$red: #ae5856;

$trans_white: rgba(255, 255, 255, 0.9);

$max_width: 1000px;

$general_transition_time: 0.5s;
$button_transition_time: 0.2s;
$link_transition_time: 0.2s;
$menu_transition_time: 0.5s;
$overlay_transition_time: 1s;

$button_font_size: 15px;
$button_letter_spacing: 0.5px;

$h1_font_size: 35px;
$h1_font_size_mobile: 25px;
$h2_font_size: 26px;
$quote_font_size: 30px;
$small_font: 14px;
$font_size: 18px;
$medium_font_size: 15px;
$h3_font_weight: 700;

// ############ RESPONSIVE ############

$padding-mobile: 30px;

$box_shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
$box_shadow_medium: 0px 0px 20px 0px rgba(100, 100, 100, 0.3);
$box_shadow_large: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);

$phone_content_width: 80%;
$medium_content_width: 80%;
$large_content_width: 60%;

$medium_max_content_width: 700px;
$large_max_content_width: 900px;

$handheld_padding: 70%;
$medium_padding: 40%;
$large_padding: 30%;

$break-small: 650px;
$break-large: 1220px;

$break-footer: 950px;

@mixin phone {
    @media only screen and (max-width: $break-small) {
        @content;
    }
}

@mixin no-phone {
    @media only screen and (min-width: $break-small + 1) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
        @content;
    }
}

@mixin no-desktop {
    @media only screen and (max-width: $break-large - 1) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: $break-large) {
        @content;
    }
}
