// Variables
@import './utilities/variables';

.contact_form_wrapper {
  background: $primary;
  &.background_white {
    background: none;
  }
}

.contact_form {
  width: $phone_content_width;
  margin: 0 auto;
  border-radius: 3px;
  padding: 0;

  max-width: 500px;
  padding-bottom: 30px;

  box-sizing: border-box;

  @include no-desktop {
    width: $medium_content_width;
    max-width: $medium_max_content_width;
  }

  @include desktop {
    width: $large_content_width;
    max-width: 500px;
  }

  .text {
    text-align: center;
    // padding: 30px;

    h1 {
      text-align: left;
      color: $white;
      .background_white & {
        color: $primary;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      color: $white;
      .background_white & {
        color: $primary;
      }
    }
  }

  .form {
    // background: green;
    min-height: 100px;
    width: 100%;
    float: left;

    // margin-left: 6.66%;
    @include phone {
      width: 100%;
      margin: 20px 0 0 0;
      transform: none;
    }

    form {
      label {
        color: $white;
        display: block;
        margin: 20px 0;
        .background_white & {
          color: $primary;
        }
        .background_white .error & {
          color: $red;
        }
        // margin-top: 20px;

        a {
          color: white;
          text-decoration: underline;
          .background_white & {
            color: $primary;
          }
        }

        input {
          // width: auto;
          // display: inline;
        }
      }

      .error label {
        color: $red;
        .background_white & {
          color: $red;
        }
        a {
          color: $red;
        }
      }

      *:first-child {
        margin-top: 0;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }
    .loading_holder {
      .spinner {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
      }
      .messages {
        margin-top: 0;
      }
    }

    button {
      transition: box-shadow 0.5s ease, transform 0.5s ease;
      transform: scale(0.98);
      cursor: pointer;
      width: 50%;
      display: block;
      text-align: center;
      margin: 0 auto;

      @include phone {
        width: 100%;
      }

      &:hover {
        transform: scale(1);
        // box-shadow: $box_shadow;
        cursor: pointer;
      }
    }
  }

  label.agb {
    margin: 20px 0;
  }
}
