// Variables
@import './utilities/variables';

.food_supplements_holder {
    position: relative;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;

    .single {
        background: $white;
        padding: 40px;
        box-sizing: border-box;
        display: block;
        width: 70%;
        margin: 50px auto;
        box-shadow: $box_shadow_large;
        position: relative;
        transition: background 0.5s ease;
        @include phone {
            box-shadow: none;
            // background: red;
            width: 100%;
            padding: 0 30px;
            background: none;
        }

        &:hover {
            background: $bg_grey;
            @include phone {
                background: none;
            }

            div p {
                text-decoration: none;
            }
        }

        .image {
            position: absolute;
            top: 5%;
            left: 3%;
            width: 44%;
            height: 90%;
            // background: blue;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @include phone {
                position: relative;
                left: 0;
                width: 100%;
                padding-bottom: 61%;
            }
        }

        .info {
            position: relative;
            left: 53%;
            width: 44%;
            text-align: left;

            @include phone {
                position: relative;
                width: 100%;
                left: 0;
                text-align: center;
                margin-top: 30px;
            }

            .description {
                margin-bottom: -10px;
                text-decoration: none;
            }

            h2 {
                margin-top: 0;
            }
        }
    }
}

.single_product.food_supplements {
    .product_description {
        @include phone {
            margin-top: 30px;
        }
        p {
            margin: 0;
        }
    }

    .product_image {
        @include phone {
            min-height: 0;
        }
        .image {
            position: absolute;
            height: 100%;
            width: 80%;
            background-position: top center;
            background-size: contain;
            background-repeat: no-repeat;
            left: 20%;
            @include phone {
                width: 100%;
                position: relative;
                left: 0;
                padding-bottom: 61%;
            }
        }
    }
}
