// Variables
@import './utilities/variables';

.quote_wrapper {
    .quote {
        // background: red;
        width: 50%;
        max-width: $max-width;
        margin: 40px auto 80px auto;

        @include tablet {
            width: 75%;
        }

        @include phone {
            width: 85%;
        }

        span {
            color: $green;
            // font-size: $quote_font_size;
            font-weight: 100;

            // : calc(50px * ((100vw - 500px) / (100 - 500)));
            // font-size: calc(
            //     1.3em + (1.5 - 1.3) * ((100vw - 500px) / (1000px - 500px))
            // );
            font-size: 17.5px;
            // color: red;

            @media (min-width: 550px) {
                // font-size: calc(15px + 0.224 * 100vw)
                font-size: calc(12px + 1vw);
                color: $primary;
            }

            // @media (min-width: 1220px) {
            // font-size: 30px;
            // }

            @include tablet {
                //     font-size: $quote_font_size * 0.7;
                //     font-weight: 300;
                font-weight: 600;
            }

            @include phone {
                //     font-size: 15px;
                font-weight: 600;
            }
        }

        span.quote_line_1 {
            position: relative;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%);
        }

        .quotee {
            position: relative;
            bottom: -42px;
            right: 20px;
        }
    }

    .quote.two_lines {
        .quote_line_1 {
            left: 0;
            transform: none;
        }

        .quote_line_2 {
            text-align: right;
            display: block;
            padding-right: 70px;
            position: relative;

            @include tablet {
                padding-left: 70px;
                padding-right: 0;
            }
            @include phone {
                padding-left: 70px;
                padding-right: 0;
            }
        }

        .quotee {
            position: absolute;
            bottom: -42px;
            right: 0;
        }
    }
}
