// Variables
@import './utilities/variables';

.footer_wrapper {
    width: 100%;
    // padding-bottom: 20%;
    background: $bg_grey;
    position: relative;
    // top: 100%;
    margin-top: 50px;
    // transform: translateY(100%);

    .footer {
        width: 100%;
        overflow-x: hidden;
        max-width: $max-width;
        margin: 0 auto;
        padding: 50px 0;

        .name {
            color: $green;
            text-align: center;
            display: block;
            letter-spacing: 1px;
            margin: 30px 0 50px 0;
        }

        .footer_menu {
            display: flex;
            justify-content: space-evenly;
            @include phone {
                display: block;
                margin: 50px 0;
            }

            a {
                display: inline-block;
                margin: 0 auto;
                color: $grey;
                transition: color $link_transition_time ease;
                @include phone {
                    display: block;
                    margin: 20px auto;
                    text-align: center;
                    color: $primary;
                    text-transform: uppercase;
                }

                &:hover {
                    color: $green;
                }
            }
        }

        .logo {
            width: 20%;
            padding-bottom: 10%;
            background: url('../img/logo.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 20px auto;
            @include phone {
                width: 50%;
                padding-bottom: 25%;
            }
        }

        .switzerland {
            width: 5%;
            padding-bottom: 8%;
            background: url('../img/icons/icon_switzerland.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 20px auto;
            @include phone {
                width: 15%;
                padding-bottom: 24%;
            }
        }
    }
}
