.sitemap {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 25px;
    z-index: 1;

    .__wrapper {
        display: flex;
        flex-wrap: wrap;

        @include phone {
            display: block;
        }
    }

    .__category {
        margin-bottom: 50px;
    }

    .__category--0,
    .__category--1 {
        width: 25%;
        flex-basis: 25%;
        flex-grow: 0;

        @include phone {
            width: 100%;
        }
        @include tablet {
            flex-basis: 33.3%;
            width: 33.3%;
        }
    }
    .__category--2 {
        width: 50%;
        flex-basis: 50%;
        flex-grow: 0;

        @include phone {
            width: 100%;
        }
    }
    .__category--3 {
        width: 75%;
        flex-basis: 75%;
        flex-grow: 0;

        @include phone {
            width: 100%;
        }
        @include tablet {
            flex-basis: 33.3%;
            width: 33.3%;
        }

        .__links {
            display: flex;
            flex-basis: 1;
            flex-wrap: wrap;

            li {
                flex-shrink: 0;
                flex-grow: 0;
                width: 33.3%;
                margin-left: 0;
                margin-right: 0;
                transform: translateX(25px);

                @include phone {
                    width: 100%;
                }
            }
        }
    }
    .__category--4 {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;

        @include phone {
            width: 100%;
        }
        @include tablet {
            flex-basis: 100%;
            width: 100%;
        }

        .__links {
            display: flex;
            flex-basis: 1;
            flex-wrap: wrap;

            li {
                flex-shrink: 0;
                flex-grow: 0;
                width: calc(25% - 35px);
                margin-left: 0;
                margin-right: 0;
                transform: translateX(25px);
                padding-right: 35px;

                @include phone {
                    width: 100%;
                }
                @include tablet {
                    width: 33.3%;
                }
            }

            
        }
    }

    .__category-name {
        font-weight: bold;
    }


}