// Variables
@import './utilities/variables';

.health_topics_display {
	.health_topics {
		max-width: 1000px;
		width: 80%;
		margin: 0 auto;
		@include phone {
			// background: red;
			width: 98%;
			display: flex;
			flex-wrap: wrap;
			justify-content: left;
		}
	}
}

.topic_preview {
	display: block;
	position: relative;
	width: 21%;
	padding-bottom: 25%;
	box-shadow: $box_shadow;
	float: left;
	background-size: cover;
	background-position: center;
	box-sizing: border-box;
	margin: 2%;
	@include phone {
		width: 46%;
		padding-bottom: 54.76%;
	}
	.green_overlay {
		position: absolute;
		height: 100%;
		background: $green;
		opacity: 0;
	}
	.text_holder {
		position: absolute;
		display: block;
		bottom: 0;
		text-align: center;
		padding: 16px 5px;
		box-sizing: border-box;
		margin: 0;
		width: 100%;
		background: $white;
		font-size: $h2_font_size;
		overflow: hidden;
		@include phone {
			font-size: $h2_font_size * 0.8;
		}
		transition: color $menu_transition_time ease,
			background $menu_transition_time ease;
		.text_holder_inner {
			width: 100%;
			// background: red;
			margin: 0;
			display: block;
			height: 100%;
			p {
				margin: 0;
				line-height: 100%;
				color: $green;
				transition: color $menu_transition_time ease;
			}
		}
	}
	&:hover {
		@include no-phone {
			.green_overlay {
				opacity: 0.5;
			}
			.text_holder {
				background: $green;
				p {
					color: $white;
				}
			}
		}
	}
}

.single_health_page {
	padding-top: 70px;
	position: relative;

	.title_with_icon {
		@include phone {
			// margin-top: 40px;
		}
	}

	.general_text {
		width: 60%;
		max-width: 900px;
		margin: 30px auto;
		text-align: center;
		iframe {
			width: 100%;
		}
	}

	.tip {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin: 50px auto;
		width: 70%;
		max-width: 1000px;
		@include phone {
			display: block;
			width: $phone_content_width;
			padding: 0 30px;
			margin: 30px auto;
		}

		.text {
			align-self: center;
			width: 50%;
			@include phone {
				width: 100%;
			}
			h1 {
				text-align: left;
			}
			p {
				text-align: left;

				iframe {
					width: 100%;
				}
			}
		}
		.image {
			padding-bottom: 35%;
			width: 35%;
			background-size: cover;
			background-position: center;
			@include phone {
				width: 125%;
				margin-left: -12.5%;
				padding-bottom: $handheld_padding * 1.25;
			}
		}
		&.image_right {
			flex-direction: row-reverse;
		}
		&.image_left {
		}
	}

	.product_link_wrapper {
		width: 100%;
		background: $bg_grey;
		padding: 30px 0;
		.product_link {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			margin: 50px auto;
			width: 70%;
			max-width: 1000px;
			flex-direction: row-reverse;
			.image {
				padding-bottom: 35%;
				width: 35%;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
			.text {
				align-self: center;
				width: 50%;
				h1 {
					text-align: left;
				}
				p {
					text-align: left;
				}
			}
		}
	}

	.content_wrapper {
		padding-bottom: 30px;
	}
}
.other_topics {
	width: 80%;
	margin: 0 auto;
	padding-bottom: 50px;
	.topic_preview {
		width: 29%;
		padding-bottom: 22%;
		@include phone {
			width: 100%;
			padding-bottom: 80%;
			margin: 20px 0;
		}
	}
}
