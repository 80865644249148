// Variables
@import './utilities/variables';

.landing_link_shop_wrapper {
    width: 100%;
    background: $bg_grey;

    .content_holder {
        max-width: $max_width;
        width: 60%;
        // background: red;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 50px;

        @include phone {
            width: 100%;
            padding: 10px $padding-mobile 30px $padding-mobile;
            box-sizing: border-box;
        }

        .image {
            width: 100%;
            padding-bottom: 45%;
        }

        .text_block {
            text-align: center;
            width: 100%;
            margin: 0 auto;
            position: relative;
            // font-size: $small_font;

            @include phone {
                padding: 0;
            }

            &.narrow {
                max-width: 500px;
            }

        }
    }
}