// Variables
@import './utilities/variables';

.green_overlay {
    background: $green;
    opacity: 0;
    transition: opacity $overlay_transition_time ease;
    position: absolute;
    width: 100%;
    height: 100%;
}

.two_images_wrapper {
    width: 100%;
    position: relative;
    margin: 50px 0 60px 0;

    @include phone {
        margin: 0;
    }

    .two_images {
        // background: red;
        max-width: $max_width;
        width: 50%;
        margin: 0 auto;

        @include phone {
            width: 100%;
        }

        // padding-bottom: 50%;
        .container_1 {
            position: relative;
            display: block;

            .image_holder {
                width: 45%;
                position: relative;
                float: left;
                padding-bottom: 70%;
                background-size: cover;
                background-position: center;

                @include phone {
                    width: 100%;
                    padding-bottom: 70%;
                    margin: 30px 0 10px 0;
                }
            }

            .text_container {
                width: 50%;
                position: relative;
                float: left;
                margin-left: 5%;
                margin-top: 30px;

                @include phone {
                    width: 100%;
                    padding: 0 $padding-mobile;
                    margin: 0;
                    box-sizing: border-box;
                    text-align: center;

                    h2 {
                        font-size: $h1_font_size;
                    }
                }

                .button {
                    position: relative;
                    left: 50%;
                    transform: translate(-50%, 0);

                    @include phone {
                        left: unset;
                        transform: unset;
                        display: inline-block;
                        margin: 0px auto 50px auto;
                    }
                }
            }
        }

        .container_2 {
            position: relative;
            display: block;

            .image_holder {
                width: 70%;
                position: relative;
                float: right;
                padding-bottom: 45%;
                background-size: cover;
                background-position: center;

                @include phone {
                    width: 100%;
                    padding-bottom: 70%;
                    margin: 30px 0 10px 0;
                }
            }

            .text_container {
                width: 25%;
                position: relative;
                float: right;
                margin-right: 5%;
                margin-top: 30px;

                @include phone {
                    width: 100%;
                    padding: 0 $padding-mobile;
                    margin: 0;
                    box-sizing: border-box;
                    text-align: center;

                    h2 {
                        font-size: $h1_font_size;
                    }
                }

                .button {
                    position: relative;
                    left: 50%;
                    transform: translate(-50%, 0);

                    @include phone {
                        left: unset;
                        transform: unset;
                        display: inline-block;
                        margin: 0px auto 50px auto;
                    }
                }
            }
        }
    }

    .two_images.layout_1 {
        .container_1 {
        }

        .container_2 {
            .image_holder {
                margin-top: -10%;

                @include phone {
                    margin-top: 0;
                }
            }

            .text_container {
                margin-top: 15px;
            }
        }
    }

    .two_images.layout_2 {
        .container_1 {
            .image_holder {
                margin-top: -10%;

                @include phone {
                    margin-top: 0;
                }
            }
        }

        .container_2 {
        }
    }
}

a:hover {
    @include no-phone {
        .green_overlay {
            opacity: 0.5;
        }

        // h2 {
        //  display: block;
        //  width: 100%;
        //  background-color: $green;
        //  height: 1px;
        // }
        h2 {
            text-decoration: underline;
        }
    }
}
