// Variables
@import './utilities/variables';

.text_block {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    position: relative;

    &.narrow {
        max-width: 500px;
    }

    img {
        min-width: 450px;
        max-width: 700px;
        margin-block: 50px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    @include phone {
        width: auto;
        padding-left: $padding-mobile;
        padding-right: $padding-mobile;
    }
}