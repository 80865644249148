// Variables
@import './utilities/variables';

.webinar_banner {
	position: absolute;
	top: 90px;
	right: 20px;
	padding: 20px;
	background: $primary;
	z-index: 999;
	color: white;
	width: 325px;
	transform-origin: 100% 0;
	transform: scale(0.9);

	box-shadow: $box-shadow;
	@include tablet {
		width: 350px;
		top: 70px;
		transform: scale(0.8);
	}
	@include phone {
		position: relative;
		width: 100%;
		height: auto;
		box-shadow: none;
		left: 0;
		top: 0;
		right: auto;
		box-sizing: border-box;
		text-align: center;
		padding: 50px 20px;
		transform: scale(1);
	}

	h3 {
		color: white;
		line-height: 110%;
		margin-top: 0;
	}
	p {
		line-height: 110%;
		color: white;
		margin-bottom: 16px;
	}
	a {
		color: white;
		margin-bottom: 0;
	}
}
