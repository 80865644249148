// Variables
@import './utilities/variables';

p {
  color: $grey;
  line-height: 180%;
  margin-bottom: 30px;
}

h1 {
  font-size: $h1_font_size;
  color: $green;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.text_block {
  h2,
  .h2 {
    color: $green;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: $h2_font_size;
  }
} 
.singup_form h2,
.singup_form .h2 {
  font-weight: 400 !important; /* weird characters that does not exists in font on safari... */
}

h2 {
  color: $green;
  letter-spacing: 0.5px;
  font-size: 22px;
  font-weight: 300;
}

h3 {
  color: $green;
}

a {
  color: $green;
  text-decoration: none;
  // left: 0;
}

a:hover {
  text-decoration: none;

  .info .description p {
    text-decoration: none;
  }
}

.align_left p {
  text-align: justify;
  word-wrap: break-word;
  hyphens: auto;
}

.text_block {
  font-size: 18px;

  img {
    width: 100%;
    height: auto;

    @include phone {
      width: calc(100% + 60px);
      height: auto;
      margin-left: -30px;
    }

    &.half_width {
      width: 50%;
      margin-left: 25%;

      @include phone {
        width: calc(100% + 60px);
        height: auto;
        margin-left: -30px;
      }
    }
  }

  .wp-caption-text {
    margin-top: 0;
  }

  div.wp-caption {
    width: 100% !important;
    margin: 30px 0;
  }
}
.align_center {
  text-align: center;
}

video,
.wp-video {
  width: 100% !important;
  height: auto;
}
