// Variables
@import './utilities/variables';

.address_form {
  width: 1000px;
  margin: 5% auto;
  text-align: left;
  padding-bottom: 100px;

  .billing {
    width: 47%;
    float: left;
  }

  .shipping {
    width: 47%;
    float: left;
    margin-left: 6%;
  }

  .submit {
    margin: 50px auto;
    display: block;
  }
}

.download_page {
  .document_holder {
    padding: 0 10%;
    box-sizing: border-box;
  }
  .file_link {
    display: inline-block;
    // width: 100%;
    // flex-direction: row;
    margin: 20px auto;
    .icon {
      float: left;
    }
    .document_name {
      font-weight: 800;
    }
    .right {
      float: left;
      flex-grow: 1;
      text-align: left;
      padding-left: 20px;
      box-sizing: border-box;
    }
    &:hover {
      .document_name {
        text-decoration: underline;
      }
    }
  }
}

.account_form {
  width: 30%;
  margin: 30px auto;

  &.address_form form {
    margin-top: 30px;
    top: 30px;
    position: relative;
  }

  div.field.address {
    // height: 300px;
    max-height: 60px;
    height: auto;
    margin-bottom: 120px;

    input {
      position: relative;
      top: 0;
    }
  }

  .field {
    // background: $bg_grey;
    position: relative;
    height: 60px;
    margin: 0 0 30px 0;
    transition: height 0.5s ease, max-height 0.5s ease, padding 0.5s ease;

    label {
      position: relative;
      visibility: hidden;
      opacity: 0;
    }

    .label {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      color: $green;
    }

    input {
      position: absolute;
      top: 30px;
      left: 0;
      width: 70%;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease, visibility 0s 0.5s;
    }

    &.password_field {
      input.old_password {
        top: 30px;
      }

      input.new_password {
        top: 90px;
      }

      input.repeat_password {
        top: 150px;
      }
    }

    .change {
      position: absolute;
      bottom: 10px;
      right: 0;
      padding: 12px 0;
      line-height: 22px;
      width: 25%;
      margin: 0;
    }

    .content {
      position: absolute;
      top: 30px;
      line-height: 22px;
      left: 0;
      opacity: 1;
      color: $grey;
      transition: opacity 0.5s 0.5s ease, visibility 0s 0.5s;

      margin: 0;
      text-align: left;
    }

    &.edit {
      height: 88px;

      &.password_field {
        height: 208px;
      }

      input {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s 0.5s ease, visibility 0s 0.5s;
      }

      label {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s 0.5s ease, visibility 0s 0.5s;
      }

      &.address {
        max-height: 1000px;
        padding-bottom: 40px;
      }

      .content {
        opacity: 0;
        top: 45px;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0s 0.5s, top 0.5s ease;
      }

      .error {
        position: absolute;
        left: 0;
        bottom: -15px;
        color: red;
      }
    }
  }
}
