// Variables
@import './utilities/variables';

.icon.shortcode {
  margin: 0 auto -25px auto;
}

%icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}

// SECONDARY MENU

%secondary_menu_icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.secondary_menu .icon_homeopathy {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_homeopathy.svg');

  &.active {
    background-image: url('../img/icons/icon_homeopathy_active.svg');
  }
}

.secondary_menu .icon_gemmotherapy {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_gemmotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_gemmotherapy_active.svg');
  }
}

.secondary_menu .icon_phytotherapy {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_phytotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_phytotherapy_active.svg');
  }
}

.secondary_menu .icon_oligotherapy {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_oligotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_oligotherapy_active.svg');
  }
}

.secondary_menu .icon_schussler_salts {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_schussler_salts.svg');

  &.active {
    background-image: url('../img/icons/icon_schussler_salts_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_schussler_salts_white.svg');
  }
}

.secondary_menu .icon_food_supplements {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_food_supplements.svg');

  &.active {
    background-image: url('../img/icons/icon_food_supplements_active.svg');
  }
}

.secondary_menu .icon_make_your_own {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_make_your_own.svg');

  &.active {
    background-image: url('../img/icons/icon_make_your_own_active.svg');
  }
}

// Secondary Menu Company

.secondary_menu .icon_story {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_story.svg');

  &.active {
    background-image: url('../img/icons/icon_story_active.svg');
  }
}

.secondary_menu .icon_lab {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_lab.svg');

  &.active {
    background-image: url('../img/icons/icon_lab_active.svg');
  }
}

.secondary_menu .icon_values {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_values.svg');

  &.active {
    background-image: url('../img/icons/icon_values_active.svg');
  }
}

.secondary_menu .icon_quality {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_quality.svg');

  &.active {
    background-image: url('../img/icons/icon_quality_active.svg');
  }
}

.secondary_menu .icon_team {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_team.svg');

  &.active {
    background-image: url('../img/icons/icon_team_active.svg');
  }
}

// Secondary Menu Science

.secondary_menu .icon_science {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_science.svg');

  &.active {
    background-image: url('../img/icons/icon_science_active.svg');
  }
}

.secondary_menu .icon_video {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_video.svg');

  &.active {
    background-image: url('../img/icons/icon_video_active.svg');
  }
}

// Seondary Menu Resources

.secondary_menu .icon_where {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_addresses.svg');

  &.active {
    background-image: url('../img/icons/icon_addresses_active.svg');
  }
}

.secondary_menu .icon_training {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_story.svg');

  &.active {
    background-image: url('../img/icons/icon_story_active.svg');
  }
}

.secondary_menu .icon_research {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_lab.svg');

  &.active {
    background-image: url('../img/icons/icon_lab_active.svg');
  }
}

.secondary_menu .icon_foundation {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_bulb.svg');

  &.active {
    background-image: url('../img/icons/icon_bulb_active.svg');
  }
}

.secondary_menu .icon_associations {
  @extend %secondary_menu_icon;
  background-image: url('../img/icons/icon_team.svg');

  &.active {
    background-image: url('../img/icons/icon_team_active.svg');
  }
}

// General Icons

.icon_shop {
  @extend %icon;
  background-image: url('../img/icons/icon_shop.svg');

  &.active {
    background-image: url('../img/icons/icon_shop_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_shop_white.svg');
  }
}

.icon_company {
  @extend %icon;
  background-image: url('../img/icons/icon_story.svg');

  &.active {
    background-image: url('../img/icons/icon_story_active.svg');
  }
}

.icon_science {
  @extend %icon;
  background-image: url('../img/icons/icon_science.svg');

  &.active {
    background-image: url('../img/icons/icon_science_active.svg');
  }
}

.icon_homeopathy {
  @extend %icon;
  background-image: url('../img/icons/icon_homeopathy.svg');

  &.active {
    background-image: url('../img/icons/icon_homeopathy_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_homeopathy_white.svg');
  }
}

.icon_gemmotherapy {
  @extend %icon;
  background-image: url('../img/icons/icon_gemmotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_gemmotherapy_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_gemmotherapy_white.svg');
  }
}

.icon_garden {
  @extend %icon;
  transform: scale(1.6)!important;
  margin-left: -15%;
  background-image: url('../img/icons/icon_garden.svg');

  &.active {
    background-image: url('../img/icons/icon_garden_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_garden.svg');
  }
}

.icon_phytotherapy {
  @extend %icon;
  background-image: url('../img/icons/icon_phytotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_phytotherapy_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_phytotherapy_white.svg');
  }
}

.icon_oligotherapy {
  @extend %icon;
  background-image: url('../img/icons/icon_oligotherapy.svg');

  &.active {
    background-image: url('../img/icons/icon_oligotherapy_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_oligotherapy_white.svg');
  }
}

.icon_schussler_salts {
  @extend %icon;
  background-image: url('../img/icons/icon_schussler_salts.svg');

  &.active {
    background-image: url('../img/icons/icon_schussler_salts_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_schussler_salts_white.svg');
  }
}

.icon_food_supplements {
  @extend %icon;
  background-image: url('../img/icons/icon_food_supplements.svg');

  &.active {
    background-image: url('../img/icons/icon_food_supplements_active.svg');
  }
}

.icon_make_your_own {
  @extend %icon;
  background-image: url('../img/icons/icon_make_your_own.svg');

  &.active {
    background-image: url('../img/icons/icon_make_your_own_active.svg');
  }
}

.icon_video {
  @extend %icon;
  background-image: url('../img/icons/icon_video.svg');

  &.active {
    background-image: url('../img/icons/icon_video_active.svg');
  }
}

.icon_lab {
  @extend %icon;
  background-image: url('../img/icons/icon_lab.svg');

  &.active {
    background-image: url('../img/icons/icon_lab_active.svg');
  }
}

.icon_single_tube {
  @extend %icon;
  background-image: url('../img/icons/icon_single_tube.svg');

  &.active {
    background-image: url('../img/icons/icon_single_tube_active.svg');
  }
}

.icon_values {
  @extend %icon;
  background-image: url('../img/icons/icon_values.svg');

  &.active {
    background-image: url('../img/icons/icon_values_active.svg');
  }
}

.icon_quality {
  @extend %icon;
  background-image: url('../img/icons/icon_quality.svg');

  &.active {
    background-image: url('../img/icons/icon_quality_active.svg');
  }
}

.icon_team {
  @extend %icon;
  background-image: url('../img/icons/icon_team.svg');

  &.active {
    background-image: url('../img/icons/icon_team_active.svg');
  }
}

.icon_story {
  @extend %icon;
  background-image: url('../img/icons/icon_story.svg');

  &.active {
    background-image: url('../img/icons/icon_story_active.svg');
  }
}

.icon_vegetale {
  @extend %icon;
  background-image: url('../img/icons/icon_vegetale.svg');

  &.active {
    background-image: url('../img/icons/icon_vegetale_active.svg');
  }
}

.icon_animale {
  @extend %icon;
  background-image: url('../img/icons/icon_animale.svg');

  &.active {
    background-image: url('../img/icons/icon_animale_active.svg');
  }
}

.icon_minerale {
  @extend %icon;
  background-image: url('../img/icons/icon_minerale.svg');

  &.active {
    background-image: url('../img/icons/icon_minerale_active.svg');
  }
}

.icon_language {
  @extend %icon;
  background-image: url('../img/icons/icon_language.svg');

  &.active {
    background-image: url('../img/icons/icon_language_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_language_white.svg');
  }
}

.icon_search {
  @extend %icon;
  background-image: url('../img/icons/icon_search.svg');

  &.active {
    background-image: url('../img/icons/icon_search_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_search_white.svg');
  }
}

.icon_user {
  @extend %icon;
  background-image: url('../img/icons/icon_user.svg');

  &.active {
    background-image: url('../img/icons/icon_user_active.svg');
  }
}

.icon_health {
  @extend %icon;
  background-image: url('../img/icons/icon_health.svg');

  &.active {
    background-image: url('../img/icons/icon_health_active.svg');
  }
}

.icon_back {
  @extend %icon;
  background-image: url('../img/icons/icon_back.svg');

  &.active {
    background-image: url('../img/icons/icon_back_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_back_white.svg');
  }
}

.icon_security {
  @extend %icon;
  background-image: url('../img/icons/icon_security.svg');

  &.active {
    background-image: url('../img/icons/icon_security_active.svg');
  }
}

.icon_addresses {
  @extend %icon;
  background-image: url('../img/icons/icon_addresses.svg');

  &.active {
    background-image: url('../img/icons/icon_addresses_active.svg');
  }
}

.icon_payment {
  @extend %icon;
  background-image: url('../img/icons/icon_payment.svg');

  &.active {
    background-image: url('../img/icons/icon_payment_active.svg');
  }
}

.icon_orders {
  @extend %icon;
  background-image: url('../img/icons/icon_orders.svg');

  &.active {
    background-image: url('../img/icons/icon_orders_active.svg');
  }
}

.icon_close {
  @extend %icon;
  background-image: url('../img/icons/icon_close.svg');

  &.active {
    background-image: url('../img/icons/icon_close_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_close_white.svg');
  }
}

.icon_cart {
  @extend %icon;
  background-image: url('../img/icons/icon_cart.svg');

  &.active {
    background-image: url('../img/icons/icon_cart_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_cart_white.svg');
  }
}

.icon_package_bottle {
  @extend %icon;
  background-image: url('../img/icons/icon_package_bottle.svg');

  &.active {
    background-image: url('../img/icons/icon_package_bottle_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_package_bottle_white.svg');
  }
}

.icon_mix {
  @extend %icon;
  background-image: url('../img/icons/icon_mix.svg');

  &.active {
    background-image: url('../img/icons/icon_mix_active.svg');
  }
}

.icon_document {
  @extend %icon;
  background-image: url('../img/icons/icon_document.svg');

  &.active {
    background-image: url('../img/icons/icon_document_active.svg');
  }
}

.icon_kit {
  @extend %icon;
  background-image: url('../img/icons/icon_kit.svg');

  &.active {
    background-image: url('../img/icons/icon_kit_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_kit_white.svg');
  }
}

.icon_forward {
  @extend %icon;
  background-image: url('../img/icons/icon_forward.svg');

  &.active {
    background-image: url('../img/icons/icon_forward_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_forward_white.svg');
  }
}

.icon_package_schussler {
  @extend %icon;
  background-image: url('../img/icons/icon_package_schussler.svg');

  &.active {
    background-image: url('../img/icons/icon_package_schussler_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_package_schussler_white.svg');
  }
}

.icon_package_dose {
  @extend %icon;
  background-image: url('../img/icons/icon_package_dose.svg');

  &.active {
    background-image: url('../img/icons/icon_package_dose_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_package_dose_white.svg');
  }
}

.icon_package_otc {
  @extend %icon;
  // background-image: url('../img/icons/icon_package_dose.svg');

  &.active {
    // background-image: url('../img/icons/icon_package_dose_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_package_otc_white.svg');
  }
}

.icon_package_unknown {
  @extend %icon;
  background-image: url('../img/icons/icon_package_unknown.svg');

  &.active {
    background-image: url('../img/icons/icon_package_unknown_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_package_unknown_white.svg');
  }
}

.icon_en {
  @extend %icon;
  background-image: url('../img/vector/flag_en.svg');
}

.icon_fr {
  @extend %icon;
  background-image: url('../img/vector/flag_fr.svg');
}

.icon_de {
  @extend %icon;
  background-image: url('../img/vector/flag_de.svg');
}

.icon_it {
  @extend %icon;
  background-image: url('../img/vector/flag_it.svg');
}

.icon_granules {
  @extend %icon;
  background-image: url('../img/icons/icon_granules.svg');

  &.active {
    background-image: url('../img/icons/icon_granules_active.svg');
  }
}

.icon_globuli {
  @extend %icon;
  background-image: url('../img/icons/icon_globuli.svg');

  &.active {
    background-image: url('../img/icons/icon_globuli_active.svg');
  }
}

.icon_creme {
  @extend %icon;
  background-image: url('../img/icons/icon_creme.svg');

  &.active {
    background-image: url('../img/icons/icon_creme_active.svg');
  }
}

.icon_drops {
  @extend %icon;
  background-image: url('../img/icons/icon_drops.svg');

  &.active {
    background-image: url('../img/icons/icon_drops_active.svg');
  }
}

.icon_distilled_water {
  @extend %icon;
  background-image: url('../img/icons/icon_distilled_water.svg');

  &.active {
    background-image: url('../img/icons/icon_distilled_water_active.svg');
  }
}

.icon_oral {
  @extend %icon;
  background-image: url('../img/icons/icon_oral.svg');

  &.active {
    background-image: url('../img/icons/icon_oral_active.svg');
  }
}

.icon_stock {
  @extend %icon;
  background-image: url('../img/icons/icon_stock.svg');

  &.active {
    background-image: url('../img/icons/icon_stock_active.svg');
  }
}

.icon_reliability {
  @extend %icon;
  background-image: url('../img/icons/icon_reliability.svg');

  &.active {
    background-image: url('../img/icons/icon_reliability_active.svg');
  }
}

.icon_respect {
  @extend %icon;
  background-image: url('../img/icons/icon_respect.svg');

  &.active {
    background-image: url('../img/icons/icon_respect_active.svg');
  }
}

.icon_precision {
  @extend %icon;
  background-image: url('../img/icons/icon_precision.svg');

  &.active {
    background-image: url('../img/icons/icon_precision_active.svg');
  }
}

.icon_harmony {
  @extend %icon;
  background-image: url('../img/icons/icon_harmony.svg');

  &.active {
    background-image: url('../img/icons/icon_harmony_active.svg');
  }
}

.icon_fingerprint {
  @extend %icon;
  background-image: url('../img/icons/icon_fingerprint.svg');

  &.active {
    background-image: url('../img/icons/icon_fingerprint_active.svg');
  }
}

.icon_supplier {
  @extend %icon;
  background-image: url('../img/icons/icon_supplier.svg');

  &.active {
    background-image: url('../img/icons/icon_supplier_active.svg');
  }
}

.icon_checklist {
  @extend %icon;
  background-image: url('../img/icons/icon_checklist.svg');

  &.active {
    background-image: url('../img/icons/icon_checklist_active.svg');
  }
}

.icon_quantity {
  @extend %icon;
  background-image: url('../img/icons/icon_quantity.svg');

  &.active {
    background-image: url('../img/icons/icon_quantity_active.svg');
  }
}

.icon_analysis {
  @extend %icon;
  background-image: url('../img/icons/icon_analysis.svg');

  &.active {
    background-image: url('../img/icons/icon_analysis_active.svg');
  }
}

.icon_doctor {
  @extend %icon;
  background-image: url('../img/icons/icon_doctor.svg');

  &.active {
    background-image: url('../img/icons/icon_doctor_active.svg');
  }
}

.icon_bulb {
  @extend %icon;
  background-image: url('../img/icons/icon_bulb.svg');

  &.active {
    background-image: url('../img/icons/icon_bulb_active.svg');
  }
}

.icon_pages {
  @extend %icon;
  background-image: url('../img/icons/icon_pages.svg');

  &.active {
    background-image: url('../img/icons/icon_pages_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_pages_white.svg');
  }
}

.icon_contact {
  @extend %icon;
  background-image: url('../img/icons/icon_contact.svg');

  &.active {
    background-image: url('../img/icons/icon_contact_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_contact_white.svg');
  }
}

.icon_facebook {
  @extend %icon;
  background-image: url('../img/icons/icon_facebook.svg');

  &.active {
    background-image: url('../img/icons/icon_facebook_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_facebook_white.svg');
  }
}

.icon_instagram {
  @extend %icon;
  background-image: url('../img/icons/icon_instagram.svg');

  &.active {
    background-image: url('../img/icons/icon_instagram_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_instagram_white.svg');
  }
}

.icon_twitter {
  @extend %icon;
  background-image: url('../img/icons/icon_twitter.svg');

  &.active {
    background-image: url('../img/icons/icon_twitter_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_twitter_white.svg');
  }
}

.icon_tiktok {
  @extend %icon;
  background-image: url('../img/icons/icon_tiktok.svg');

  &.active {
    background-image: url('../img/icons/icon_tiktok_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_tiktok_white.svg');
  }
}

.icon_linkedin {
  @extend %icon;
  background-image: url('../img/icons/icon_linkedin.svg');

  &.active {
    background-image: url('../img/icons/icon_linkedin_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_linkedin_white.svg');
  }
}

.icon_youtube {
  @extend %icon;
  background-image: url('../img/icons/icon_youtube.svg');

  &.active {
    background-image: url('../img/icons/icon_youtube_active.svg');
  }

  &.white {
    background-image: url('../img/icons/icon_youtube_white.svg');
  }
}

.icon_downloads {
  @extend %icon;
  background-image: url('../img/icons/icon_downloads.svg');

  &.active {
    background-image: url('../img/icons/icon_downloads_active.svg');
  }

  .icon_where {
    @extend %icon;
    background-image: url('../img/icons/icon_search.svg');

    &.active {
      background-image: url('../img/icons/icon_search_active.svg');
    }
  }

  .icon_training {
    @extend %icon;
    background-image: url('../img/icons/icon_story.svg');

    &.active {
      background-image: url('../img/icons/icon_story_active.svg');
    }
  }

  .icon_research {
    @extend %icon;
    background-image: url('../img/icons/icon_lab.svg');

    &.active {
      background-image: url('../img/icons/icon_lab_active.svg');
    }
  }

  .icon_associations {
    @extend %icon;
    background-image: url('../img/icons/icon_team.svg');

    &.active {
      background-image: url('../img/icons/icon_team_active.svg');
    }
  }

  .icon_media {
    @extend %icon;
    background-image: url('../img/icons/icon_document.svg');

    &.active {
      background-image: url('../img/icons/icon_document_active.svg');
    }
  }
}
