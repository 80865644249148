@import './utilities/variables';

.page_content.checkout {
    .iframe_checkout {
        width: 100%;
        // height: 100%;
        frameborder: 0;
        border: 0;
        cellspacing: 0;
        overflow: hidden;
        // background: #ccc;
        // z-index: 101;
        position: relative;
        transition: height 0.5s ease;
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: $white;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0s 0.5s;

        // z-index: 102;
        &.visible {
            opacity: 1;
            visibility: visible;
        }
    }

    .spinner {
        top: 200px;
        // z-index: 100;
    }
}