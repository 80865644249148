// Variables
@import './utilities/variables';
.app_wrapper.loading {
	.app_content {
		// filter: blur(5px);
		opacity: 0.25;
	}
}

.message {
	width: 300px;
	// padding-bottom: 20%;
	background: white;
	border-radius: 5px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -20%);
	z-index: 1011;
	visibility: hidden;
	opacity: 0;
	text-align: center;
	transition: opacity 0.5s ease, visibility 0s 0.5s, transform 0s 0.5s ease;
	box-shadow: $box_shadow_large;
	padding: 30px;

	@include no-desktop {
		width: 80%;
		box-sizing: border-box;
	}
	&.visible {
		transition: opacity 0.5s 0s ease, visibility 0s, transform 0.5s 0s ease;
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, -50%);
	}
	p {
		// position: absolute;
		// top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);
		// margin: 0;
		position: relative;
		text-align: center;
		margin: 30px 0;
		@include no-desktop {
			margin: 10px 0;
		}
	}
	button.ok {
		margin: 0 auto 15px auto;
		// margin: 0 auto 0 auto;
		&:hover {
			background: white;
		}
	}
}

.message_overlay {
	background: rgba(255, 255, 255, 0);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1010;
	display: block;
	visibility: hidden;
	transition: background 0.5s ease, visibility 0s 0.5s;
	&.visible {
		// display: block;
		visibility: visible;
		background: rgba(255, 255, 255, 0.7);
		transition: background 1s ease, visibility 0s 0s;
	}
}
