// Variables
@import './utilities/variables';

.complexe_holder {
  margin-bottom: 50px;
  margin-top: 50px;
  max-width: $max-width;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .image {
    position: relative;
    width: 100%;
    padding-bottom: 20%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
    h1 {
      color: $white;
      font-weight: 500;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .text_holder {
    width: 50%;
    max-width: $max-width;
    margin: 120px auto 0 auto;
  }
  // .box {
  // 	position: absolute;
  // 	width: 60%;
  // 	text-align: center;
  // 	left: 50%;

  justify-content: center;
  // 	transform: translate3d(-50%, -50%, 0);
}

// ---------------- ALSO MAKE THIS ACCESSIBLE TO COMPLEXES SLIDER ---------------- //

.single_complexe {
  padding: 40px 20% 15% 20px;
  // float: left;
  position: relative;
  box-sizing: border-box;
  display: block;
  box-shadow: $box_shadow;
  width: 30%;
  background: $white;
  margin: 1.5% 1.5%;
  text-align: left;
  overflow: hidden;
  @include phone {
    width: 100%;
    padding-bottom: 45%;
    margin: 20px 1.5%;
  }
  .text {
    margin: 30px 0 0;
    width: 50%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    // background: red;
    p {
      // z-index: 1;
      line-height: 120%;
      margin: 0;
      font-size: $small_font;
      @include phone {
        font-size: $small_font * 1.25;
      }
    }
    .nr_substances {
      @include phone {
        // display: none;
      }
    }
    .title {
      color: $green;
      text-decoration: none;
      font-weight: 500;
      font-size: $font_size;
      @include phone {
        font-size: $font-size * 1.25;
      }
    }
  }

  .no {
    position: absolute;
    left: 6px;
    top: -37px;
    margin: 0;
    font-size: 100px;
    color: $bg_grey;
    z-index: 0;
    opacity: 0.7;
    transition: opacity 0.5s ease, color 0.5s ease;
    @include phone {
      font-size: 125px;
    }
  }

  .image {
    // background: blue;#

    // z-index: 0;
    position: absolute;
    top: 30px;
    right: -15%;
    width: 80%;
    height: 150%;
    background: transparent url('../img/tube.png') no-repeat top/contain;
    transition: top 0.5s ease;
    &.complexe_15 {
      top: 30px;
      right: -15%;
      width: 80%;
      height: 110%;
      background: transparent url('../img/complexe_15.png') no-repeat
        top/contain;
    }
    &.complexe_22 {
      top: 30px;
      right: -15%;
      width: 80%;
      height: 110%;
      background: transparent url('../img/complexe_22.png') no-repeat
        top/contain;
    }
  }

  &:hover {
    .title {
      text-decoration: underline;
    }
    .image {
      top: 20px;
      @include phone {
        top: 30px;
      }
    }
    // }
  }

  .icon {
    margin: 0 auto;
    width: 70px;
    height: 70px;
  }
}
