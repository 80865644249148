// Variables
@import './utilities/variables';

.banner_wrapper {
    width: 100%;
    padding-bottom: 35%;
    height: 0px;
    /*background: red;*/
    position: relative;

    /*margin-top: -140px;*/
    @include phone {
        padding-bottom: unset;
        height: unset;
    }
}

.banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;

    @include phone {
        bottom: unset;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
    }

    .darken {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: #000;
    }

    /*background: green;*/
    .products_holder {
        // background: red;
        width: 60%;
        position: absolute;
        bottom: 35%;
        left: 50%;
        transform: translateX(-50%);
        height: 30%;
        text-align: center;
        display: flex;
        justify-content: center;

        @include phone {
            position: relative;
            height: unset;
            margin-top: 10px;
            bottom: unset;
            width: 80%;
        }

        .product_link {
            display: block;
            height: auto;
            width: auto;
            margin: 0 20px;
            padding: 0 20px;
            position: relative;
            flex-shrink: 1;

            // text-align: center;

            @include phone {
                height: unset;
                width: 30%;
                overflow: hidden;
                // background: red;
                text-align: center;
                padding: 0;
                margin: 0 2%;
            }

            // background: green;
            .icon {
                height: 80%;
                margin: 0 auto;
                transition: transform 0.5s ease;
                transform: translate(0, 0);
                width: 130px;

                @include phone {
                    width: 100%;
                    padding-bottom: 150%;
                    height: 0;
                }
            }

            .product_name {
                color: white;
                text-decoration: none;
                display: block;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
                font-size: 15px;
            }

            &:hover {
                // background: red;
                .product_name {
                    text-decoration: underline;
                }

                .icon {
                    transform: translate(0, -10px);
                }
            }
        }
    }
}

.banner h1 {
    position: absolute;
    top: 1vw;
    font-weight: 100;
    width: 100%;
    letter-spacing: 1px;

    @include phone {
        position: relative;
        top: unset;
        padding: 30px 30px 0 30px;
        margin-top: 0;
        display: block;
        box-sizing: border-box;
        font-size: $h1_font_size_mobile;
    }
}

.banner h1.green {
    color: $green;
}

.banner h1.white {
    color: $white;
}

.banner a.button {
    background: none;
    border: 1px solid;
    color: white;
    padding: 15px 20px;
    font-size: 15px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    border-color: white;
    transform: translateX(-50%);
    letter-spacing: 0.5px;
    transition: background $button_transition_time ease,
        color $button_transition_time ease,
        border-color $button_transition_time ease;
    cursor: pointer;

    @include phone {
        position: relative;
        margin-top: 30px;
        top: auto;
        bottom: auto;
        margin-bottom: 30px;
    }

    &:hover {
        background: $green;
        color: $white;
        border-color: $green;
    }
}
