// Variables
@import './utilities/variables';

.header_image {
	background-color: $bg_grey;
	padding-bottom: 25%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include phone {
		padding-bottom: 70%;
	}
}
