// Variables
@import './utilities/variables';

.social_feed {
	background: $bg_grey;

	.social_feed_wrapper {
		width: $large_content_width;
		max-width: $large_max_content_width;
		margin: 0 auto;
		padding: 30px 0;

		@include phone {
			width: auto;
			padding-left: $padding-mobile;
			padding-right: $padding-mobile;
		}
	}

	.referral {
		display: none !important;
	}

	.j-loading {
		border-color: #000 !important;
	}

	.j-display-filters {
		display: none !important;
	}

	.j-loading:before {
		background-color: #000 !important;
	}
}

.j-overlay .j-overlay-content {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate3d(-50%, -50%, 0);
	animation: feed_in 0.5s;
	animation-timing-function: ease-out;
}

@keyframes feed_in {
	0% {
		opacity: 0;
		// transform: translate3d(-50%, -40%, 0);
		transform: translate3d(-50%, -50%, 0) scale(0.8);
	}

	100% {
		opacity: 1;
		transform: translate3d(-50%, -50%, 0) scale(1);
	}
}
