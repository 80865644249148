@import './utilities/variables';
.search_display {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $green;
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s 0.5s;
  @include no-desktop {
    z-index: 1502;
  }
  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease, visibility 0s 0s;
    .search_bar {
      transition: left 1s ease, width 1s ease;
      left: 150px;
      width: 60%;
      left: 15%;
      @include no-desktop {
        width: 100%;
        left: 0;
        padding: 0 90px 0 30px;
        box-sizing: border-box;
      }
    }
  }
  .home_link {
    display: block;
    width: 100px;
    height: 50px;
    background: url('../img/logo_white.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 7px 10px 10px 10px;
    z-index: 1004;
    padding: 0;
    border: 0px;
    @include no-desktop {
      display: none;
    }
  }
  .search_bar {
    position: absolute;
    left: 150px;
    width: 0%;
    left: 100%;
    top: 7px;
    transition: left 0s 1s ease, width 0s 1s ease;
    // background: green;
    input {
      background: none;
      border-radius: 0;
      border-width: 0 0 2px 0;
      border-color: $white;
      padding: 12px;
      margin: 0;
      color: $white;
      &:active,
      &:focus {
        border-width: 0 0 2px 0;
        border-radius: 0;
        padding: 12px;
      }
    }
  }

  .search_results {
    position: absolute;
    left: 15%;
    top: 70px;
    padding-left: 52px;
    width: 60%;
    box-sizing: border-box;
    // overflow-y: auto;
    // max-height: 90%;
    @include no-desktop {
      left: 0;
      padding: 0 30px 0 82px;
      width: 100%;
      box-sizing: border-box;
    }
    p {
      margin: 0;
      color: $white;
    }
    .type_more {
      position: absolute;
      left: 30px;
      width: 100%;
    }
    h2 {
      color: #ffffff;
      margin: 20px 0 5px 0;
      position: relative;
      // padding-left: 35px;
      .icon {
        display: inline-block;
        position: absolute;
        left: -43px;
        width: 32px;
        top: -2px;
        height: 33px;
      }
    }
    button {
      color: $white;
      display: block;
      line-height: 25px;
      text-align: left;
    }
    .focus {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    // background: red;
    padding: 10px;
    box-sizing: border-box;
    margin: 0;
    border: 0px;
    // opacity: 0.7;
    // transition: opacity 0.5s ease;
    &:hover {
      // opacity: 1;
      background: none;
    }
    .icon {
      width: 100%;
      height: 100%;
    }
  }
}
