// Variables
@import './utilities/variables';

.link_shop_wrapper {
	margin-bottom: 250px;
	margin-top: 100px;
	@include phone {
		margin: 50px 0;
		padding: 50px;
		position: relative;
	}
	.image {
		position: relative;
		width: 100%;
		padding-bottom: 30%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include phone {
			margin: 30px 0;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
		}
	}
	.box {
		position: absolute;
		background: $white;
		box-shadow: $box_shadow;
		width: 40%;
		text-align: center;
		padding: 20px;
		margin: 0 auto;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		@include phone {
			position: relative;
			margin: 0;
			box-sizing: border-box;
			left: 0%;
			width: 100%;
			transform: none;
			// transform: translate3d(-50%, 0, 0);
		}

		.icon {
			margin: 0 auto;
			width: 70px;
			height: 70px;
		}
	}
}
