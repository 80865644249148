// Variables
@import './utilities/variables';

div.cart {
  width: 30%;
  height: 100%;
  background: $green;
  position: fixed;
  right: 0;
  top: 70px;
  z-index: 1002;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.3);
  transform: translateX(0);
  transition: transform 0.5s ease;
  padding: 20px;
  box-sizing: border-box;
  @include no-desktop {
    top: 50px;
    width: 100%;
  }

  &.hidden {
    transform: translateX(120%);
  }

  h2 {
    margin-top: 0;
  }

  p {
    color: $white;
  }

  .cart_items {
    position: absolute;
    width: 100%;
    top: 65px;
    left: 0;
    padding: 0 20px;
    overflow: scroll;
    /* height: 100%; */
    box-sizing: border-box;
    bottom: 200px;

    .item {
      clear: both;
      margin-left: -10px;
      position: relative;
      margin: 0 0 0 -10px;
      padding: 12.5px 0 12.5px 45px;

      // display: flex;
      // justify-content: space-between;
      .text {
        color: $white;
        position: relative;
        font-size: 13px;

        .name {
          display: block;
          font-weight: 600;
        }

        .info {
          display: block;
        }

        .right {
          position: absolute;
          bottom: 0;
          right: 10px;

          .quantity {
            margin-right: 10px;
          }
        }

        .remove {
          width: 50px;
          height: 100%;
          position: absolute;
          top: 50%;
          right: -10px;
          transform: translate(0, -50%);
          // display: none;
          opacity: 0;
          transition: opacity 0.2s ease;
          border: none; // background: $green;
          @include phone {
            opacity: 1;
          }

          .icon {
            width: 15px;
            height: 88%;
            position: absolute;
            top: 0;
            right: 0;
            background-position: bottom;
          }
        }
      }

      .product_icon {
        position: absolute;
        float: left;
        height: 26px;
        width: 31px;
        background-position: center;
        margin-right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
      }

      .remove {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        // display: none;
        // opacity: 0;
      }

      &:hover {
        .remove {
          // display: block;
          opacity: 0.7;
          background: none;

          &:hover {
            opacity: 1;
          }
        }

        .price {
          // opacity: 0;
        }
      }
    }
  }

  .total {
    position: absolute;
    bottom: 100px;
    left: 0;
    padding: 20px;
    color: $white;
    width: 100%;
    box-sizing: border-box;

    .total_sum,
    .discount_sum,
    .final_sum,
    .shipping_sum {
      float: right;
      margin-right: 5px;
    }

    .final_text,
    .final_sum {
      font-weight: 700;
    }
  }

  .checkout_button {
    position: absolute;
    bottom: 80px;
    right: 18px;
    color: white;

    button {
      background: none;
      border: 0px;
      color: white;
      font-weight: 700;

      &:hover {
        background: none;
        border: none;

        span {
          text-decoration: underline;
        }
      }

      &[disabled]:hover span {
        text-decoration: none;
      }
    }

    .icon {
      width: 20px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  button.close {
    width: 25px;
    height: 25px;
    // background: $green;
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 0;
    opacity: 0.8;
    transition: opacity 0.5s ease;
    border: none;

    &:hover {
      background: none;
      opacity: 1;
    }

    .icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.cart_button {
  display: block;
  position: relative;
  // bottom: 50px;
  // right: 50px;
  background: red;
  // width: 50px;
  // height: 50px;
  // border-radius: 50%;
  // z-index: 999;
  // box-shadow: $box_shadow;
  // transition: opacity 0.5s ease;
  padding: 0;
  transition: all 0.2s ease;

  // transition-timing-function: cubic-bezier(0, 2, 1, 0.5);
  .icon {
    // background: red;
    width: 100%;
    height: 100%;
  }

  &.hidden {
    // opacity: 0;
  }

  &:hover {
    // background: $white;
    // width: 55px;
    // height: 55px;
    // bottom: 47px;
    // right: 47px;
    .number {
      transform: scale(1.2);
    }
  }

  .number {
    position: absolute;
    top: 10px;
    right: 2px;
    background: $primary;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $white;
    transition: transform 0.2s cubic-bezier(0.315, 0.95, 0.565, 1.18);

    span {
      position: absolute;
      font-size: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
