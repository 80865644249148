// Variables
@import './utilities/variables';

.product_display {
    width: 1000px;
    margin: 0 auto;
    text-align: center;

    @include phone {
        width: 100%;
    }

    .description {
        @include phone {
            width: 80%;
            margin: 0 auto;
        }
    }

    .icon {
        margin: 30px auto 0 auto;
    }

    .kits_slider_element {
        position: relative;

        .loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s ease, visibility 0s 0.5s;

            &.active {
                visibility: visible;
                opacity: 1;
                transition: opacity 0.5s ease, visibility 0s 0s;
            }

            p {
                position: absolute;
                margin: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .info_holder {
            position: relative;
            width: 60%;
            margin: 0 auto;
            height: 150px;
            @include phone {
                display: none;
            }

            .info {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s 0.3s, opacity 0.3s ease,
                    margin-top 0 0.3s;
                position: absolute;
                top: 0;
                width: 100%;
                margin-top: 10px;

                .title_box {
                    text-align: left;
                }

                .title {
                    text-align: left;
                    display: inline-block;
                    margin: 20px 0 0 0;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &.active {
                    visibility: visible;
                    opacity: 1;
                    margin-top: 0;
                    transition: visibility 0s 0.5s, opacity 1.5s 0.5s ease,
                        margin-top 1.5s 0.5s ease;
                }

                .product_image {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-bottom: 30%;
                    // background: red;
                    width: 40%;
                    transform: translate(0, -50%);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                .description {
                    text-align: left;
                    width: 60%;

                    p {
                        margin: 10px 0;
                    }
                }

                .nr_remedies {
                    margin-left: 25px;
                    color: $grey;
                }
            }
        }

        .kits_slider_holder {
            width: 80%;
            margin: 0 auto;
            overflow: hidden;
            position: relative;

            .nav_button_holder {
                position: absolute;
                padding-top: 50%;
                width: 50px;
                // background: red;

                // width: 100%;
                &.left {
                    left: 0%;
                }

                &.right {
                    right: 0%;
                }

                .nav_button {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    background: none;
                    border: none;
                    height: 50px;
                    width: 50px;
                    left: 0;

                    .icon {
                        position: absolute;
                        top: 50%;
                        width: 30px;
                        height: 30px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        // background: blue;
                        margin: 0;
                        transition: opacity 0.2s ease;
                        opacity: 1;
                        @include phone {
                            width: 50px;
                            height: 50px;
                            opacity: 0;
                        }

                        &.active {
                            opacity: 0;
                            @include phone {
                                opacity: 1;
                            }
                        }
                    }

                    &:hover {
                        background: none;

                        .icon {
                            opacity: 0;

                            &.active {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .kits_slider {
                position: relative;
                transition: left 0.5s ease;

                .slide {
                    width: 100%;
                    margin: 0 auto;
                    // background: $bg_grey;
                    float: left;
                    position: relative;

                    &:hover {
                        .info {
                            .product_image {
                                transform: translate(0, -55%);
                                @include phone {
                                    transform: none;
                                }
                            }

                            .title {
                                text-decoration: underline;
                            }
                        }
                    }

                    .topic_image {
                        display: block;
                        position: relative;
                        width: 80%;
                        margin: 0 auto;
                        padding-bottom: 50%;
                        background-size: cover;
                        background-position: center;
                        @include phone {
                            display: none;
                        }
                    }

                    .info {
                        visibility: hidden;
                        opacity: 0;
                        display: block;
                        // transition: visibility 0s 1s, opacity 1s ease,
                        // margin-top 0 1s;
                        transition: visibility 0s 1s ease, opacity 0.3s ease,
                            margin 0s 1s ease;
                        position: relative;
                        top: 0;
                        width: 75%;
                        margin: 0 auto;
                        margin-top: 10px;
                        @include phone {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }

                        .title_box {
                            text-align: left;
                            @include phone {
                                text-align: center;
                            }
                        }

                        .title {
                            text-align: left;
                            display: inline-block;
                            margin: 20px 0 0 0;
                            @include phone {
                                font-size: 125%;
                                display: block;
                                text-align: center;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &.active {
                            visibility: visible;
                            opacity: 1;
                            margin-top: 0;
                            transition: visibility 0s 0.5s,
                                opacity 1.5s 0.5s ease,
                                margin-top 1.5s 0.5s ease;
                        }

                        .product_image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding-bottom: 30%;
                            // background: red;
                            width: 40%;
                            transform: translate(0, -50%);
                            transition: transform 0.5s ease-in-out;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            @include phone {
                                position: relative;
                                width: 100%;
                                padding: 0 30px;
                                box-sizing: border-box;
                                transform: none;
                                padding-bottom: 61%;
                            }
                        }

                        .description {
                            text-align: left;
                            width: 60%;

                            @include phone {
                                width: 100%;
                                text-align: center;
                            }

                            p {
                                margin: 10px 0;
                            }
                        }

                        .nr_remedies {
                            margin-left: 25px;
                            color: $grey;
                            @include phone {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
