// Variables
@import './utilities/variables';

.cookie_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.75s ease, visibility 0s 0.75s ease;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.react-cookie-law-dialog {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: green;
  z-index: 10000;
  padding: 10px 0 20px 0;
}

.react-cookie-law-container {
  width: 60%;
  margin: 0 auto;
  max-width: 900px;
  color: white;
  padding: 30px;
  @include phone {
    width: 80%;
  }
}

.react-cookie-law-policy {
  float: left;
}

.react-cookie-law-button-wrapper {
  clear: both;
  padding-top: 10px;
  float: right;
  @include phone {
    padding-top: 20px;
  }
}

.react-cookie-law-select-pane {
  padding: 20px 0 10px 0;
  display: table-cell;
}

.react-cookie-law-option-wrapper input[type='checkbox'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  color: $white;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.react-cookie-law-option-wrapper input[type='checkbox'].white + label {
  .react-cookie-law-option-wrapper input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
  }

  .react-cookie-law-option-wrapper input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
  }

  .react-cookie-law-option-wrapper
    input[type='checkbox']:checked
    + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    border-width: 2px;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.react-cookie-law-accept-btn {
  background: $white;
  color: $primary;
  border: 1px solid $white;
  margin: 0 10px;

  &:hover {
    border: 1px solid $white;
    background: none;
    color: $white;
  }
}

.react-cookie-law-manage-btn,
.react-cookie-law-save-btn {
  margin: 0 10px;
  background: none;
  color: white;
  border: 1px solid $white;

  &:hover {
    background: $white;
    color: $primary;
  }
}
