// Variables
@import './utilities/variables';

.post_display {
	// background: red;
	padding-bottom: 30px;

	img {
		max-width: 100%;
		height: auto;
	}

	.overview {
		max-width: $max_width;
		width: 50%;
		margin: 0 auto;
		min-height: 200px;

		@include tablet {
			width: 85%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		@include phone {
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0;
			box-sizing: border-box;
			margin: 50px 0;

			@include phone {
				display: block;
				width: 100%;
				margin: 20px 0 50px 0;
			}

			.image {
				position: relative;
				width: 40%;
				padding-bottom: 25%;
				background-color: grey;
				background-size: cover;
				background-position: center;

				@include phone {
					width: 100%;
					padding-bottom: 61%;
				}
			}

			.description {
				width: 60%;
				text-align: left;
				box-sizing: border-box;
				padding: 0 0 0 10%;

				@include phone {
					display: block;
					width: 100%;
					padding: 0;
				}

				.date {
					margin: 10px 0;
					display: block;
				}

				h1 {
					text-align: left;
					margin-bottom: 0;
				}

				.center {
					text-align: center;
				}
			}
		}
	}

	a:hover {
		@include no-phone {
			.green_overlay {
				opacity: 0.5;
			}

			h1 {
				text-decoration: underline;
			}
		}
	}

	.single {
		.post_wrapper {
			max-width: $max_width;
			width: 50%;
			margin: 0 auto;
			min-height: 200px;
			margin-top: 50px;

			@include tablet {
				width: 65%;
				box-sizing: border-box;
			}

			@include phone {
				width: 100%;
				padding: 0 30px;
				box-sizing: border-box;
			}

			iframe {
				width: 100%;
			}

			img {
				min-width: 450px;
				max-width: 700px;
				margin-block: 50px;
				position: relative;
				left: 50%;
				transform: translateX(-50%);

				@include phone {
					width: calc(100% + 60px);
					height: auto;
					margin-left: -30px;
					max-width: none;
				}

				&.half_width {
					width: 50%;
					margin-left: 25%;

					@include tablet {
						width: 70%;
						margin-left: 15%;
					}

					@include phone {
						width: calc(100% + 60px);
						height: auto;
						margin-left: -30px;
					}
				}
			}

			.wp-caption-text {
				margin-top: 0;
			}

			div.wp-caption {
				width: 100% !important;
				margin: 30px 0;
			}

			.date {
				display: block;
				text-align: center;
				margin: 0 0 20px 0;
				color: $green;
				font-size: $font_size;
			}

			.center {
				text-align: center;
			}
		}
	}
}

.post_block {
	background: $bg_grey;
	padding: 50px 0;
	margin: 50px 0;

	@include phone {
		padding: 30px 0;
	}

	.posts_wrapper {
		max-width: $max_width;
		width: 60%;
		margin: 0 auto;
		min-height: 200px;

		@include tablet {
			width: 85%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		@include phone {
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		.single_post {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0;
			box-sizing: border-box;
			margin: 50px 0;

			@include phone {
				display: block;
				width: 100%;
				margin: 20px 0;
			}

			.image_link {
				position: relative;
				width: 40%;
				padding-bottom: 25%;
				display: block;

				@include phone {
					width: 100%;
					padding-bottom: 61%;
				}

				.image {
					width: 100%;
					height: 100%;
					position: absolute;
					background-color: grey;
					background-size: cover;
					background-position: center;
				}
			}

			.description {
				width: 60%;
				text-align: left;
				box-sizing: border-box;
				padding: 0 0 0 10%;

				@include phone {
					display: block;
					width: 100%;
					padding: 0;
				}

				.date {
					margin: 10px 0;
					display: block;
				}

				h1 {
					text-align: left;
					margin-bottom: 0;

					&:hover {
						text-decoration: underline;
					}
				}

				.button_wrapper {
					@include phone {
						text-align: center;
					}

					.button {
						// float: left;
						@include tablet {
							// clear: both;
						}
					}

					.button.show_all {
						margin-left: 20px;
						@include tablet {
							// margin-left: 0;
						}
					}
				}
			}
		}

		.single_post:hover {
			@include no-phone {
				.green_overlay {
					opacity: 0.5;
				}
			}
		}
	}
}
