// Variables
@import './utilities/variables';

.seasonal_remedy_wrapper {
    margin-bottom: 50px;
    margin-top: 50px;

    .image {
        position: relative;
        width: 100%;
        padding-bottom: 20%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include phone {
            padding: 10% 30px;
            box-sizing: border-box;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.1);
        }

        h1 {
            color: $white;
            font-weight: 500;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include phone {
                transform: none;
                position: relative;
                left: 0;
                top: 0;
            }
        }
    }

    .text_holder {
        width: 50%;
        max-width: $max-width;
        margin: 150px auto 0 auto;
        @include phone {
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            margin: 30px auto;
        }
    }

    .box {
        position: absolute;
        width: 60%;
        text-align: center;
        left: 50%;
        display: flex;

        justify-content: center;
        transform: translate3d(-50%, -50%, 0);

        @include phone {
            position: relative;
            transform: none;
            display: block;
            transform: none;
            width: 80%;
            margin: 30px auto;
            left: 0;
        }

        .remedy {
            padding: 20px 20px 20% 20px;
            // padding-bottom: 30%;
            position: relative;
            box-sizing: border-box;
            display: block;
            box-shadow: $box_shadow;
            width: 30%;
            background: $white;
            margin: 0 1.5%;
            text-align: left;
            overflow: hidden;
            @include phone {
                width: 100%;
                margin: 30px 0;
                padding: 20px;
            }

            .text {
                // margin: 30px 0 0;
                width: 100%;
                position: absolute;
                bottom: 20px;
                left: 0px;

                text-align: center;

                @include phone {
                    position: relative;
                    bottom: unset;
                    margin-top: 20px;
                }

                // background: red;
                p {
                    // z-index: 1;
                    line-height: 120%;
                    margin: 5px 0;
                    font-size: $small_font;
                }

                .title {
                    color: $green;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: $font_size;
                }
            }

            .image {
                //  // background: blue;#

                //  // z-index: 0;
                //  position: absolute;
                //  top: 30px;
                //  right: -15%;
                //  width: 80%;
                //  height: 150%;
                //  background: transparent url('../img/tube.png') no-repeat
                //      top/contain;
                //  transition: top 0.5s ease;

                position: absolute;
                top: 25px;
                left: 10%;
                width: 80%;
                padding: 0;
                height: 50%;
                transform: translate3d(0, 0, 0);
                background: transparent url('../img/otc_generic.jpg') no-repeat
                    top/contain;
                transition: transform 0.5s ease-in-out;

                @include phone {
                    position: relative;
                    left: 10%;
                    top: 0;
                    padding-bottom: 50%;
                }
            }

            @include no-phone {
                &:hover {
                    .title {
                        text-decoration: underline;
                    }

                    .image {
                        // top: 20px;
                        transform: translate3d(0, -10px, 0);
                    }
                }
            }
        }

        .icon {
            margin: 0 auto;
            width: 70px;
            height: 70px;
        }
    }
}
