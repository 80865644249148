// Variables
@import './utilities/variables';

.back_link {
	display: inline-block;
	position: relative;
	line-height: 30px;
	padding: 25px;
	position: absolute;
	z-index: 1000;
	@include phone {
		padding: 15px;
		position: relative;
	}
	.icon {
		width: 30px;
		height: 30px;
		display: inline-block;
		position: absolute;

		top: 50%;
		transform: translateY(-50%);
	}
	span {
		padding-left: 30px;
	}
	&:hover {
		span {
			text-decoration: underline;
		}
	}
}
