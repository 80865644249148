@import './utilities/variables';

.social_icons {
	@include phone {
		display: flex;
		padding: 0 30px;
	}
	text-align: center;
	a {
		width: 4vw;
		height: 4vw;
		// background: red;
		overflow: hidden;
		position: relative;
		display: inline-block;
		margin: 0 1vw;
		// padding: 0.5vw;
		@include phone {
			width: 20vw;
			height: 20vw;
			margin: 0;
		}
		.icon {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-position: center;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 1;
			transform: translate3d(0, 0px, 0);
			// transform: scale(0.9);
			transition: opacity 0.5s ease, transform 0.5s ease-in-out;
			&.active {
				opacity: 0;
				// box-shadow: $box-shadow;
			}
		}
		@include no-phone {
			&:hover {
				.icon {
					opacity: 0;
					transform: translate3d(0, -5px, 0);
					// transform: scale(1);
					&.active {
						opacity: 1;
					}
				}
			}
		}
	}
}
