// Variables
@import './utilities/variables';

.single_product {
  @include phone {
    padding-top: 70px;
  }
  // &.single_schussler
  .single_product_wrapper {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    @include phone {
      padding: 0 30px;
      box-sizing: border-box;
    }

    .product_image {
      padding-bottom: 30%;
      width: 35%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // background-color: $bg_grey;
      float: left;
      position: relative;
      @include phone {
        width: 100%;
        // padding: 0 30px;
        // box-sizing: border-box;
        padding-bottom: 0%;
        float: none;
        position: relative;
      }

      .icon {
        width: 77%;
        height: 85%;
        position: absolute;
        left: -13px;
        opacity: 0.2;
        top: -18%;
        @include phone {
          width: 100%;
          top: -30%;
          left: -20%;
        }
      }

      .schussler_image {
        background-image: url('../img/products/schussler.png');
        position: absolute;
        top: 0;
        right: 0;
        width: 69%;
        height: 80%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @include phone {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          margin-bottom: 30px;
        }
      }

      .complexe_image {
        background-image: url('../img/tube.png');
        position: absolute;
        top: 0;
        right: 0;
        width: 69%;
        height: 80%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.complexe_15 {
          background: transparent url('../img/complexe_15.png') no-repeat
            top/contain;
        }
        &.complexe_22 {
          background: transparent url('../img/complexe_22.png') no-repeat
            top/contain;
        }
        @include phone {
          // position: relative;
          width: 100%;
        }
      }

      .otc_image {
        background-image: url('../img/otc_generic.jpg');
        position: absolute;
        top: 0;
        right: 10%;
        width: 69%;
        height: 80%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @include phone {
          width: 80%;
          right: 10;
        }
      }

      @include phone {
        min-height: 300px;
        width: 100%;
      }
    }

    .product_info {
      width: 60%;
      float: left;
      margin-left: 5%;
      // background: $bg_grey;
      position: relative;
      color: $grey;
      @include phone {
        text-align: center;
        // width: 80%;
        width: 100%;
        margin: 0px auto 30px auto;
        float: none;
        padding-top: 0px;
      }

      .product_description {
        // white-space: pre-line;
        font-size: 18px;
        // text-align: left;

        p {
          margin-top: 10px;
        }

        h2 {
          margin-bottom: 0;
        }

        ul {
          padding-left: 10px;
          li {
            margin: 0 0 16px 13px;
          }
        }

        h3,
        h3 span {
          font-weight: $h3_font_weight !important;
          font-size: 18px;
          margin-bottom: 7px;
          @include phone {
          }
        }
        .posology {
          img {
            max-width: 400px;
            height: auto;
            @include phone {
              max-width: 100%;
            }
          }
        }
      }

      .info_box_holder {
        .info_box {
          float: left;
          width: 30%;
          font-size: $small_font;
          @include phone {
            font-size: 18px;
            width: 100%;
            margin-bottom: 30px;
          }

          h3 {
            margin-bottom: 7px;

            @include phone {
              font-size: 18px;
            }
          }

          p {
            margin: 0;
          }

          &.two_column {
            width: 60%;
            @include phone {
              width: 100%;
            }

            div {
              column-count: 2;
              @include phone {
                column-count: 1;
              }
            }
          }
        }
      }

      .schussler_select {
        position: relative;
        margin-top: 30px;
        width: 200px;
        float: left;
        @include phone {
          float: none;
          margin: 30px auto;
        }

        .schussler_select__control {
          color: $green;
          height: 40px;
          cursor: pointer;

          &:hover {
            border: 1px solid $green;
          }

          &.schussler_select__control--is-focused {
            border: 1px solid $green;
            box-shadow: none;
          }

          .schussler_select__single-value {
            color: $green;
          }

          &.schussler_select__control--has-value {
            color: $green;
          }

          .schussler_select__indicators {
            height: 40px;
          }

          .schussler_select__value-container {
            height: 40px;
          }
        }

        .schussler_select__option {
          color: $grey;
          height: 40px;
          cursor: pointer;

          &.schussler_select__option--is-focused {
            color: $green;
            background: $bg_grey;
          }

          &.schussler_select__option--is-selected {
            background: $green;
            color: $white;
          }
        }
      }

      .quantity_field {
        margin: 30px 30px 5px 0;
        float: left;
        @include phone {
          display: block;
          margin: 10px auto;
          float: none;
          clear: both;
        }
      }

      .schussler_quantity .quantity_field {
        margin-right: 0;
      }

      .buy_section {
        display: inline-block;
        @include phone {
          display: block;
          float: none;
          padding-top: 20px;
        }
        &.schussler_quantity {
          width: 100%;
          .schussler_right {
            float: right;
            @include phone {
              width: 100%;
              float: none;
            }
            .quantity_field {
              float: right;
              @include phone {
                display: block;
                margin: 30px auto;
                float: none;
                clear: both;
                margin: 10px auto;
              }
            }
            .buy {
              clear: both;
              float: right;
              @include phone {
                display: block;
                float: none;
                margin: 30px auto;
              }
            }
          }
        }
      }

      .buy {
        position: relative;
        float: left;
        margin-top: 30px;
        @include phone {
          position: relative;
          display: inline-block;
          float: none;
          margin: 10px auto;
          background: $primary;
          color: $white;
        }

        &.new_line {
          clear: both;
          margin-top: 10px;
        }
        &:disabled.highlight,
        &:disabled.highlight:hover {
          border-color: $grey;
          color: $grey;
          background: none;
        }
      }

      .price {
        display: block;
        float: left;
        margin-top: 30px;
        line-height: 45px;
        margin-right: 30px;

        color: $grey;
        @include phone {
          float: none;
          margin: 10px 0;
        }
      }
    }
  }
}
