// Variables
@import './utilities/variables';
button,
a.button {
    outline: none;
    font-size: $button_font_size;
    letter-spacing: $button_letter_spacing;
    margin: 10px 0px;

    &:active {
        outline: none;
    }

    &.link {
        border: none;
        padding: 0;
        margin: 0;

        &:hover {
            background: none;
            text-decoration: underline;
            color: $green;
        }
    }

    &.red {
        color: $red;
        border: 1px solid $red;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    &.outline.white {
        background: none;
        color: white;
        border: 1px solid $white;

        &:hover {
            // color: $grey;
            // background: $white;
        }

        &.hover_white:hover {
            background: $white;
            color: $primary;
        }
    }
}

a.button,
button {
    border-radius: 3px;
    display: inline-block;
    border: 1px solid $green;
    padding: 10px 20px;
    background: none;
    color: $green;
    cursor: pointer;
    transition: background $button_transition_time ease,
        color $button_transition_time ease;

    &:hover,
    &.active {
        background: $green;
        color: $white;
    }

    @include phone {
        &:hover {
            background: none;
            color: $primary;
        }

        &.active,
        &.active:hover {
            background: $green;
            color: $white;
        }
    }

    &.primary {
        background: $green;
        color: $white;

        &:hover {
            border: 1px solid $green;
            background: none;
            color: $green;
        }
    }

    &:disabled,
    &[disabled] {
        &,
        &:hover {
            border: 1px solid $grey;
            color: $grey;
            background: none;
            cursor: default;
            text-decoration: none;
        }
    }

    &.highlight {
        background: $primary;
        color: $white;

        &:hover {
            @include no-phone {
                background: transparent;
                color: $primary;
            }
        }
    }
}

button.link {
    border: none;
    padding: none;
    display: inline-block;
}

button.blk {
    display: inline-block;
    margin: 20px;
}
