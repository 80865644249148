// Variables
@import './utilities/variables';

.main_menu {
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.85);
  position: fixed;
  top: 0;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease-in-out, height 0.5s 0.5s ease-in-out;
  &.visible {
    opacity: 1;
  }

  visibility: hidden;
  @include desktop {
    visibility: visible;
    height: 70px;
  }
}

// .secondary_menu {
//  width: 100%;
//  height: 70px;
//  background: rgba(255, 255, 255, 0.85);
//  z-index: 999;
//  position: absolute;
//  text-align: center;
//  top: 70px;
// }
.main_menu a {
  font-family: 'Nunito', sans-serif;
  text-decoration: none;
  color: $grey;
  padding: 10px 15px;
  line-height: 50px;
  /*text-transform: uppercase;*/
  letter-spacing: 0.2px;
  transition: all 0.5s;
}

.main_menu a:hover,
.main_menu a.active {
  color: $green;
}

.main_menu .menu_center {
  position: absolute;
  left: 15%;
  top: 0;
  width: 70%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.main_menu .home_link {
  display: block;
  width: 100px;
  height: 50px;
  background: url('../img/logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 7px 10px 10px 10px;
  z-index: 1004;
  padding: 0;
  float: left;
}

.main_menu a.contact_button_menu {
  left: 140px;
  margin: 0;
  position: fixed;
  top: 33px;
  transform: translateY(-50%);
  padding: 10px 20px;
  border: 1px solid $primary;
  color: $primary;
  display: inline-block;
  line-height: 100%;
  &:hover {
    color: $white;
    background: $primary;
  }
}

.icon_secondary {
  width: 30%;
  height: 40px;
  margin-top: 0px;
  // background: red;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.secondary_menu {
  position: absolute;
  top: 70px;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s 0.5s ease-in-out;

  visibility: hidden;

  @include desktop {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s 1s ease-in-out;
  }

  a {
    position: relative;
    cursor: pointer;
    display: inline-block;

    p {
      color: $grey;
      margin-top: 45px;
      transition: color $menu_transition_time;
      margin-bottom: 0;
      white-space: nowrap;
    }

    .icon_secondary {
      opacity: 1;
      transition: opacity $menu_transition_time;
    }

    .icon_secondary.active {
      opacity: 0;
      transition: opacity $menu_transition_time;
    }
  }
}

.secondary_menu a:hover,
.secondary_menu a.active {
  p {
    color: $green;
  }

  .icon_secondary {
    opacity: 0;
  }

  .icon_secondary.active {
    opacity: 1;
  }
}

.menu_right {
  position: absolute;
  top: 0;
  right: 20px;

  & > a,
  & > div,
  & > button {
    float: left;
    display: block;
    margin: 0;
    // background: red;
    // width: 35px;
    // height: 35px;
    overflow: hidden;
    padding: 17.5px 10px;
    border: none;
    background: none;
    cursor: pointer;

    .icon {
      width: 35px;
      height: 35px;
    }
  }

  .initials,
  button:hover .initials {
    width: 30px;
    height: 30px;
    border: 2px solid $green;
    border-radius: 50%;
    position: relative;

    span {
      color: $green;
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      font-size: 15px;
      font-weight: 500;
    }
  }
}

// .secondary_menu {
//  top: 70px;
//  position: absolute;
//  left: 50%;
//  transform: translate(-50%, 0);
//  margin: 0 auto;
//  width: 100%;
//  height: 70px;
//  text-align: center;
//  z-index: 1000;
//  a {
//      background: $bg_grey;
//      display: inline-block;
//      padding: 0 20px;
//      height: 100%;
//      p {
//          position: relative;
//          margin-top: 39px;
//      }
//      .icon {
//          display: none;
//      }
//  }
// }
