// Variables
@import './utilities/variables';

.essentials_display_wrapper {
    width: 100%;
    background: $white;
    position: relative;

    .essentials_display {
        max-width: $max_width;
        position: relative;
        // background: red;
        margin: 0 auto;
        text-align: center;

        .description {
            @include phone {
                width: 80%;
                margin: 0 auto;
            }
        }

        .icon {
            width: 50px;
            height: 50px;
            display: inline-block;
            margin-top: 30px;
        }

        h1 {
            margin-top: 0;
        }

        .button {
            @include phone {
                color: $white;
                background: $green;
            }
        }

        .loading {
            position: relative;

            background: $white;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s ease, visibility 0s 0.5s;

            &.active {
                padding-bottom: 13%;
                margin-bottom: 30px;
                visibility: visible;
                opacity: 1;
                transition: opacity 0.5s ease, visibility 0s 0s;
            }
        }

        .slider_navigation_wrapper {
            position: relative;

            @include phone {
                width: 100%;
                left: 0%;
            }

            .navigation {
                position: absolute;
                top: 50%;
                border: none;
                transform: translateY(-50%);
                margin: 0;
                opacity: 0;
                visibility: hidden;
                padding: 20px;
                transition: opacity 0.5s ease, visibility 0s 0.5s ease;

                @include phone {
                    padding: 30px;
                }

                &.visible {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity 0.5s ease, visibility 0s 0s ease;
                }

                &:hover {
                    background: none;
                    border: none;
                }

                &.left {
                    left: -60px;

                    @include phone {
                        left: -5%;
                    }
                }

                &.right {
                    right: -60px;

                    @include phone {
                        right: -5%;
                    }
                }

                &:hover {
                    &.left .icon {
                        transform: translateX(-5px);
                    }

                    &.right .icon {
                        transform: translateX(5px);
                    }
                }

                .icon {
                    margin: 0;
                    width: 35px;
                    height: 35px;
                    transition: transform 0.5s ease;
                    @include phone {
                        width: 50px;
                        height: 50px;
                    }
                }
            }

            .slider_wrapper {
                width: 100%;
                overflow: hidden;
                padding: 20px 0;
                height: auto;

                @include phone {
                    width: 70%;
                    left: 15%;
                }

                .slider {
                    width: 175%;
                    position: relative;
                    transition: left 1s ease;

                    .single_remedy {
                        position: relative;
                        padding-bottom: 0;
                        box-shadow: $box_shadow;
                        display: block;
                        float: left;
                        background: $white;
                        margin: 10px 1%;
                        width: 7.5%;
                        transition: background $general_transition_time ease;

                        .spacer {
                            position: relative;
                            width: 100%;
                            padding-bottom: 161%;
                        }

                        &:hover {
                            @include no-phone {
                                background: $bg_grey;
                            }
                        }

                        .tube {
                            background: url('../img/tube.png');
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            width: 100%;
                            padding-bottom: 100%;
                            position: absolute;
                            top: 30px;
                            transition: top 0.5s ease;
                        }

                        p {
                            position: absolute;
                            bottom: 13%;
                            display: block;
                            font-size: 15px;
                            text-align: center;
                            color: #64b246;
                            letter-spacing: 0.2px;
                            margin-bottom: 0;
                            width: 80%;
                            left: 10%;
                            line-height: 120%;
                            transform: translateY(50%);
                            @include phone {
                                font-size: 125%;
                            }
                        }

                        &:hover {
                            .tube {
                                @include no-phone {
                                    top: 20px;
                                }
                            }

                            p {
                                @include no-phone {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
