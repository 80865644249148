.slider_wrapper {
  width: 100%;
  padding-bottom: 35%;

  @include phone {
    padding-bottom: 70%;
  }

  height: 0px;
  /*background: red;*/
  position: relative;
  /*margin-top: -140px;*/
}

.slider_page_1 {
  width: 100%;
  height: 100%;
  position: relative;
  /*background: url(./img/slider.jpg);*/
  background-size: cover;
  background-position: center;
  /*padding: 30px 0;*/
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /*background: green;*/
  .slide {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0.01;
    position: absolute;
    top: 0;
    z-index: 100;
    visibility: visible;
    // transition: opacity 0.5s ease, visibility 0s 1s ease;
    transition: opacity 0s 1s ease, visibility 0s 1s ease;

    &.visible {
      transition: opacity 0s 0.5s ease, visibility 0s 0.5s ease;
      visibility: visible;
      opacity: 1;
      z-index: 101;
    }

      &:after {
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
        pointer-events: none;
      }


    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
    }

    .slide_video_player {
      @include phone {
        display: none;
      }
    }

    &.red {
      background: red;
    }

    &.green {
      background: green;
    }

    overflow: hidden;

    .text_holder {
      opacity: 0;
      position: absolute;
      display: block;
      z-index: 1;
      transition: opacity 0.5s ease, transform 0.5s ease;
      // background: red;
      width: 60%;
      max-width: 1000px;
      bottom: 10%;
      left: 50%;
      padding-left: 200px;
      padding-bottom: 10%;
      box-sizing: border-box;

      @include phone {
        width: 100%;
        left: 50%;
        bottom: 20px;
        padding-bottom: 30%;
        padding-left: 50%;
      }

      &.fade_in {
        transform: translate3d(-60%, 0, 0);
      }

      &.fade_out {
        transform: translate3d(-40%, 0, 0);
      }

      &:hover * {
        text-decoration: none;
      }

      &.visible {
        opacity: 1;
        transform: translate(-50%);
      }

      .images_holder {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 70%;
        height: auto;
        text-align: center;
        padding-bottom: 0%;
        transition: transform 0.5s ease;
        transform: translate3d(0, 0, 0);
        justify-content: center;
        display: flex;
        align-items: end;
        gap: 10px;

        .__img-wrapper {
          height: 100%;
        }

        &--1 {
          width: 40%;
        }
        &--2 {
          width: 55%;
        }
        &--3 {
          width: 70%;
        }

        &--regular {
          height: 250px;
        }
        &--tall {
          height: 500px;
        }

        &--large {
          height: 500px;
        }
        &--large.images_holder--1 {
          width: 60%;
          transform: translateX(-50px);
        }
        &--large.images_holder--2 {
          width: 75%;
          transform: translateX(-90px);
        }
        &--large.images_holder--3 {
          width: 90%;
          transform: translateX(-130px);
        }

        &--larger {
          height: 600px;
        }
        &--larger.images_holder--1 {
          width: 70%;
          transform: translateX(-50px);
        }
        &--larger.images_holder--2 {
          width: 85%;
          transform: translateX(-150px);
        }
        &--larger.images_holder--3 {
          width: 100%;
          transform: translateX(-200px);
        }

        img {
          position: relative;
          max-height: 100%;
          max-width: 100%;
          top: 100%;
          transform: translateY(-100%);

          @include phone {
            height: 100px;
            width: auto !important;
          }
        }

        &--1 + .text {
          left: calc(45% + 20px);
          width: 55%;
        }
        &--2 + .text {
          left: calc(55% + 20px);
          width: 45%;
        }
        &--3 + .text {
          left: calc(70% + 20px);
          width: 30%;
        }
        &--1.images_holder--large + .text {
          left: calc(60% + 20px);
          transform: translateX(-50px) translateY(-50%);
        }
        &--2.images_holder--large + .text {
          left: calc(75% + 20px);
          transform: translateX(-90px) translateY(-50%);
        }
        &--3.images_holder--large + .text {
          left: calc(90% + 20px);
          transform: translateX(-130px) translateY(-50%);
        }
        &--1.images_holder--larger + .text {
          left: calc(70% + 20px);
          transform: translateX(-50px) translateY(-50%);
        }
        &--2.images_holder--larger + .text {
          left: calc(85% + 20px);
          transform: translateX(-150px) translateY(-50%);
        }
        &--3.images_holder--larger + .text {
          left: calc(100% + 20px);
          transform: translateX(-200px) translateY(-50%);
        }


        @include phone {
          bottom: 0 !important;
          left: 50% !important;
          transform: translate(-50%, -30px) !important;
          height: 50%;

          & + .text {
            top: 0;
            left: 50% !important;
            width: 80% !important;;
            text-align: center;
            transform: translateX(-50%) translateY(-100%) !important;
          }
        }
      }

      .text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        h2 {
          position: relative;
          color: white;
          margin: 0;
          font-weight: 700;

          @include phone {
            font-size: 15px;
            // position: absolute;
            // text-align: left;
          }

          // top: 30px;
          // width: 100%;
          // text-align: center;
        }

        h1 {
          font-weight: 700;
          color: white;
          position: relative;

          @include phone {
            font-size: 25px;
            // position: absolute;
            // text-align: left;
          }

          // top: 100px;
          // width: 100%;
          display: inline;
        }
      }
    }
  }
}
