// Variables
@import './utilities/variables';

.quantity_field {
    // background: blue;
    display: block;
    // float: right;
    margin: 10px 0;
    width: 100px;
    position: relative;

    input.quantity {
        margin: 0;
        padding: 10px 0;
        line-height: 100%;
        width: 100%;
        text-align: center;

        &:focus {
            margin-top: -1px;
        }
    }

    .change_qty {
        position: absolute;
        top: 0;
        // background: red;
        width: 35%;
        text-align: center;
        height: 100%;
        border: 0px;
        padding: 0;
        // transform: translate(0, -50%);
        margin: 0;
        color: $primary;
        transition: opacity 0.5s ease;
        font-size: 120%;

        &:hover {
            background: transparent;
            color: $primary;
            opacity: 0.5;
            @include phone {
                opacity: 1;
            }
        }

        &.add {
            right: 0px;
        }

        &.substract {
            left: 0px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
