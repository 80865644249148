// Variables
@import './utilities/variables';

.nomenclature_download_block {
	background: $bg_grey;
	padding: 50px 0;
	margin: 50px 0;

	@include phone {
		padding: 30px 0;
	}

	.nomenclature_download_wrapper {
		max-width: $max_width;
		width: 60%;
		margin: 0 auto;
		min-height: 200px;

		@include tablet {
			width: 85%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		@include phone {
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
		}

		.nomenclature_download {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0;
			box-sizing: border-box;
			margin: 50px 0;

			@include phone {
				display: block;
				width: 100%;
				margin: 20px 0;
			}

			.image_link {
				position: relative;
				width: 25%;
				padding-bottom: 35%;
				display: block;

				@include phone {
					width: 50%;
					padding-bottom: 70%;
					left: 25%;
				}

				.image {
					width: 100%;
					height: 100%;
					position: absolute;
					background-color: grey;
					background-size: cover;
					background-position: center;
				}
			}

			.description {
				width: 60%;
				text-align: left;
				box-sizing: border-box;
				padding: 0 0 0 15%;

				@include phone {
					display: block;
					width: 100%;
					padding: 0;
					text-align: center;
				}

				.date {
					margin: 10px 0;
					display: block;
				}

				h1 {
					text-align: left;
					margin-bottom: 0;

					@include phone {
						text-align: center;
					}
					&:hover {
						// text-decoration: underline;
					}
				}

				.button_wrapper {
					@include phone {
						text-align: center;
					}

					.button {
						// float: left;
						@include tablet {
							// clear: both;
						}
					}

					.button.show_all {
						margin-left: 20px;
						@include tablet {
							// margin-left: 0;
						}
					}
				}
			}
		}

		.single_post:hover {
			@include no-phone {
				.green_overlay {
					opacity: 0.5;
				}
			}
		}
	}
}
