// Variables
@import './utilities/variables';

@keyframes custom_fade_in {
	0% {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0px, 0);
	}
}

// @keyframes custom_fade_in_phone {
// 	0% {
// 		opacity: 0.1;
// 	}
// 	100% {
// 		opacity: 0.99;
// 	}
// }

.react-reveal {
	position: relative;
	// @include phone {
	// opacity: 0;
	// transition: opacity 1s ease-out, transform 1s ease-out;
	// transform: translateY(100px);
	// }
	&.custom_fade_in {
		// @include no-phone {
		animation: custom_fade_in;
		animation-timing-function: ease-out;
		// }
		// @include phone {
		// animation: custom_fade_in_phone;
		// animation-timing-function: ease-in-out;
		// opacity: 1;
		// transform: translateY(0px);
		// }
	}
}
