// Variables
@import './utilities/variables';

iframe[title='Messenger button'] {
  z-index: 1000 !important;
}

iframe[title='Messenger'] {
  z-index: 2147483100 !important;
}
