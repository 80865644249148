// Variables
@import './utilities/variables';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Nunito', sans-serif;
}

html,
body {
    /*background: grey;*/
    padding: 0;
    /*padding-top: 35px;*/
    margin: 0;
    // height: auto;
    /*min-height: 200%;*/
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    min-width: 350px;
}

/*color: #64b246*/
/*#868686*/

body {
    /*padding-top: 140px;*/
}

.app_wrapper,
#root {
    // height: 100%;
    // position: relative;
}

.mobile_only {
    @include no-phone {
        display: none !important;
    }
}

.desktop_only {
    @include phone {
        display: none !important;
    }
}

.app_wrapper {
    overflow-x: hidden;
}

.page_content {
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
    transition: padding 0.5s 0.5s ease-in-out;
    // padding-bottom: 50px;

    @include phone {
        padding-top: 0;
    }

    &.padding_top_70 {
        padding-top: 70px;

        @include phone {
            padding-top: 50px;
        }
        @include tablet {
            padding-top: 50px;
        }
    }

    &.padding_top_140 {
        padding-top: 50px;
        @include desktop {
            padding-top: 140px;
        }
    }
}

html {
    // height: 100%;
    overflow: auto;
}

body {
    // height: 100%;
}

.clearfix {
    clear: both;
}

h1 {
    color: #64b246;
    text-align: center;

    &.left {
        text-align: left;
    }
}

h1.white {
    color: white;
}

p.general_text {
    text-align: center;
    width: 50%;
    margin-left: 25%;
    color: #868686;
}

ul {
    text-align: left;
    color: $grey;
    padding-inline-start: 0;

    li {
        display: block;
        list-style: none;
        /* Give the bullet room on the left hand side */
        margin: 0 0 16px 2.1225em;
        @include phone {
            margin: 0 0 16px 1.1225em;
            // margin: 0 0 16px 0;
        }

        padding: 0;
        position: relative;
    }

    li::before {
        color: $green;
        /* color of bullet or square */

        content: '\2022';
        // content: '\2661';
        /* Unicode of character to precede the list item */

        display: inline-block;
        font-size: 1em;
        /* use em or % */

        left: -1.1225em;
        /* use em, line up bullet flush with left hand side */

        position: absolute;
        /* Set the bullet positioned absolutely top left */

        top: 0em;
        /* use em or % */
    }
}

.seasonal_banner {
    // background: url(../img/seasonal_cover.jpg);
    // background-size: cover;
    // background-position: center;
    // padding-bottom: 30%;
    // padding-top: 3%;
}

.seasonal_banner h1 {
    /*margin-top: 0px;*/
}

.loading_screen {
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s;
    z-index: 1005;
}

.loading_screen.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0s 0.5s;
}

.loading_screen p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    margin: 0;
}

.app_wrapper.loading {
    // position: fixed;
    // height: 100%;
    // width: 100%;
    // top: 0;
    // overflow: hidden;
}
