// Variables
@import './utilities/variables';

.contact_map {
	background-color: $bg_grey;
	padding-bottom: 25%;
	background-position: center;
	background-size: cover;
	position: relative;
	&.phone_only {
		display: none;
	}
	@include phone {
		padding-bottom: 70%;
		&.phone_only {
			display: block;
		}
		&.dektop_only {
			display: none;
		}
	}

	.content_wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.test_marker {
			width: 5px;
			height: 5px;
			// background: red;
		}
		.lab_marker {
			transform: translate(-50%, -100%);
			height: 50px;
			width: 50px;
			position: relative;
			.icon {
				position: relative;
				width: 100%;
				height: 100%;
				// background: blue;
				svg {
					width: 100%;
					height: 100%;
				}
			}
			.text_holder {
				@include no-desktop {
					display: none;
				}
				background-color: $green;
				min-width: 300px;
				min-height: 100px;
				position: absolute;
				left: 70px;
				padding: 30px;
				top: 0;
				color: $white;
				border-radius: 3px;
				box-shadow: $box-shadow;
				font-size: $medium_font_size;
				&::before {
					content: '';
					position: absolute;
					left: -10px;
					top: 10px;
					width: 20px;
					height: 20px;
					// background: $green;
					transform: rotate(45deg);
					// box-shadow: $box-shadow;
				}
				* {
					color: $white;
				}
				a:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.address_holder.phone_only {
	position: relative;
	display: block;
	padding: 30px 30px 0 30px;
	text-align: center;
}
