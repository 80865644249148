// Variables
@import './utilities/variables';

.three_columns {
  // padding-bottom: 15%;
  width: 50%;
  margin-top: 50px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  transition: width 0.5s 0.5s ease, left 0.5s 0.5s ease;


  @include phone {
    display: block;
    left: 0;
    width: 100%;
  }
  @include tablet {
    left: 10%;
    width: 80%;
  }

  .column {
    padding: 35px 15px 50px 15px;
    text-align: center;
    position: relative;
    width: 33%;
    transition: padding 0.5s 0.5s ease;
    &.no_button {
      padding-bottom: 15px;
    }
    @include phone {
      width: 100%;
      box-sizing: border-box;
      margin: 30px 0;
      &.no_button {
        padding-bottom: 0;
      }
    }

    .button {
      position: absolute;
      bottom: 0px;
      left: 50%;
      max-width: 80%;
      transform: translateX(-50%);
      white-space: nowrap;
      overflow: hidden;
      @include phone {
        background: $primary;
        color: $white;
      }
    }

    .icon {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.three_columns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .column {
    flex: 1;
    padding: 20px;
    text-align: center;

    .icon {
      margin-bottom: 10px;
    }

    .button {
      margin-top: 10px;
    }
  }
}
.text{
  margin-bottom: 50px;
}
.swiper-container {
  width: 100%;
  height: full;
  margin-bottom: 20px;
  max-width: 1200px;

  .swiper-slide {
    display: flex;
    justify-content: center; /* Centrer les colonnes horizontalement */
    align-items: center;
    text-align: center;

    .three_columns {
      display: flex;
      justify-content: center; /* Centrer les colonnes horizontalement */
      width: 100%;

      .column {
        flex: 1;
        padding: 20px;
        text-align: center;

        .icon {
          margin-bottom: 10px;
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $primary;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: $primary;
      color: $white;
      transform: scale(1.1);
    }

    &::after {
      font-size: 20px;
    }
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev {
    left: 10px;
  }
}