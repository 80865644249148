// Variables
@import './utilities/variables';

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease, visibility 0s;
    opacity: 1;
    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0s 0.5s;
    }

    svg {
        .st0 {
            fill: none;
            stroke: #64b445;
            stroke-width: 3.3646;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
            stroke-dasharray: 260;
            animation: spinner_animation;
            animation-direction: alternate;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: cubic-bezier(0.55, 0, 0.55, 1);
        }
    }
}

@keyframes spinner_animation {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 260;
    }
}
