@import './utilities/variables';

.maps-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -125px;

  @media (max-width: 1500px) {
    margin-top: -100px;
  }

  @media (max-height: 950px) {
    height: auto;
    margin-top: -15px;
  }
}

.map-toggle {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .toggle-button {
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid $green;
    background-color: white;
    color: $green;
    cursor: pointer;
    transition: background-color 0.3s;

    &.active {
      background-color: $green;
      color: white;
    }

    &:hover {
      background-color: $green;
      color: white;
    }
  }
}

.map-wrapper {
  width: 80%;
  height: calc(80vh - 70px);
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  .recenter-button {
    position: absolute;
    top: 80px;
    left: 10px;
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(100, 178, 70, 0.5);
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -300px;

  @media (max-height: 750px) {
    height: 100vh;
    margin-top: 20px;
    justify-content: flex-start;
  }
}

.loading-title {
  color: $green;
  font-size: 48px;
  margin-bottom: 150px;
  position: relative;
  z-index: 1;
}

.spinner {
  z-index: 0;
}

@media (max-width: 768px) {
  .maps-container {
    margin-top: 0;
    padding-top: 20px;
    height: auto;
  }

  .map-toggle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    justify-items: center;
    margin-bottom: 10px;

    .toggle-button {
      width: 90%;
      padding: 10px;
    }
  }

  .map-wrapper {
    width: 100%;
    height: 70vh;
  }

  .loading-title {
    font-size: 24px;
    margin-bottom: 50px;
  }
}
