// Variables
@import './utilities/variables';

.title_with_icon {
	text-align: center;
	padding-top: 30px;
	@include phone {
		width: 100%;
		box-sizing: border-box;
		padding: 30px;
	}
	.icon {
		width: 50px;
		height: 50px;
		display: inline-block;
	}
	h1 {
		margin-top: 0;
		margin-bottom: 30px;
		@include phone {
			margin: 0;
		}
	}
}
