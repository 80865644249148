// Variables
@import './utilities/variables';

.app_content {
  min-height: 100%;
  position: relative;
  opacity: 1;
  transition: filter 0.5s ease, opacity 0.5s ease;
  padding-bottom: 1px;
}

.popup_overlay {
  background: rgba(255, 255, 0, 0);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1001;
  display: block;
  visibility: hidden;
  transition: background 1.5s ease, visibility 0s 1.5s;
  &.visible {
    // display: block;
    visibility: visible;
    background: rgba(0, 0, 0, 0.2);
    transition: background 1.5s ease, visibility 0s 0s;
    @include phone {
      background: $white;
      transition: background 0.5s ease, visibility 0s 0s;
    }
  }
}

.popup {
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: block;
  background: white;
  width: 25%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  padding: 30px;
  transform: translate3d(-50%, -20%, 0);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease, visibility 0s 1s, transform 0s 1s ease;
  max-height: 80%;
  overflow-y: auto;
  max-width: 300px;

  @include phone {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    left: 0;
    border-radius: 0;
    transform: none;
    box-shadow: none;
    max-width: none;
  }

  .hide_top {
    position: absolute;
    top: 0;
    width: 100%;
    background: white;
    height: 30px;
  }
  .hide_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: white;
    height: 30px;
  }

  &.visible {
    transition: opacity 1s 0.5s ease, visibility 0s, transform 1s 0.5s ease;
    visibility: visible;
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
    @include phone {
      transform: translate3d(0, -50%, 0);
      // top: 50px;
      // bottom: 0px;
    }
  }

  button.close_popup {
    width: 25px;
    height: 25px;
    // background: $green;
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 0;
    opacity: 0.8;
    transition: opacity 0.5s ease;
    border: none;
    margin: 0;
    &:hover {
      background: none;
      opacity: 1;
    }
    .icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  h2 {
    margin-top: 0;
    padding: 0 30px;
  }
  form button {
    box-sizing: 0%;
    margin: 10px 0;
    line-height: 150%;
    padding: 12px;
    box-sizing: border-box;
    width: 100%;
  }
  .or  {
    margin: 10px 0;
  }
  form {
    .loading {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white;
      opacity: 0.9;
      display: none;
      border-radius: 5px;
      span {
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        display: block;
        width: 100%;
        color: $green;
      }
    }
    &.loading .loading {
      display: block;
    }
  }
  form input {
    color: $green;
    display: block;
    width: 100%;
    margin: 10px 0;
    line-height: 150%;
    padding: 12px;
    border-radius: 3px;
    // color: $green;
    border: 1px solid $green;
    font-size: 15px;
    box-sizing: border-box;
    @include phone {
      font-size: 16px;
    }
    &::placeholder {
      color: $green;
    }
    &:focus {
      outline: none;
      border: 2px solid $green;
      padding: 11px;
      color: $green;
    }
  }
  form label {
    color: $green;
  }
  form .error input {
    color: $red;
    border: solid 2px $red;
    padding: 11px;
    &::placeholder {
      color: $red;
    }
    &:focus {
      color: $green;
      border: solid 2px $green;
      padding: 11px;
      &::placeholder {
        color: $green;
      }
    }
  }
}

p.or {
  margin: 0;
}
