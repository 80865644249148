// Variables
@import './utilities/variables';

.history_display_wrapper {
    .history_display {
        width: 65%;
        max-width: $max-width;
        margin: 40px auto 80px auto;
        min-height: 100px;
        // background: red;
        position: relative;
        padding: 5px 0;
        @include phone {
            width: $phone_content_width;
        }

        .history_element {
            width: 100%;
            position: relative;
            // background: red;
            padding: 70px 0;
            @include phone {
                padding: 0px 0;
            }

            // height: 100px;
            .text_box {
                width: 40%;
                @include phone {
                    width: 85%;
                    margin-left: 15%;
                }

                h2 {
                    @include no-phone {
                        margin-top: 0;
                    }
                }
            }

            .image {
                width: 40%;
                padding-bottom: 26%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-top: 5px;
                @include phone {
                    width: 85%;
                    margin-left: 15%;
                    padding-bottom: $handheld_padding * 0.85;
                }
            }

            .year {
                top: 65px;
                @include phone {
                    top: -5px;
                    margin: 0;
                    // padding: 0;
                }
            }
        }

        .layout_1 {
            @include no-phone {
                .image {
                    float: left;
                }

                .text_box {
                    float: left;
                    margin-left: 20%;
                }
            }
        }

        .layout_2 {
            @include no-phone {
                .image {
                    float: right;
                }

                .text_box {
                    float: right;
                    margin-right: 20%;
                    text-align: right;
                }
            }
        }

        .line {
            width: 2px;
            position: absolute;
            background: $green;
            // height: 100%;
            top: 70px;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            @include phone {
                left: 0;
                top: 20px;
            }
        }

        .year {
            background: white;
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 0;
            color: $green;
            @include phone {
                font-size: 125%;
                left: 0;
            }
        }

        .founded {
            top: 0;
        }

        .today {
            bottom: 0;
        }
    }
}
