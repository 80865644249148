// Variables
@import './utilities/variables';

.schussler_holder {
    max-width: $max-width;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a.single {
        display: block;
        width: 30%;
        // float: left;
        text-align: left;
        background: $white;
        margin: 1.5%;
        box-shadow: $box-shadow;
        padding: 40px 20% 15% 20px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        @include phone {
            width: 100%;
            padding-bottom: 45%;
            margin: 20px 1.5%;
        }

        .no {
            position: absolute;
            left: 6px;
            top: -37px;
            margin: 0;
            font-size: 100px;
            color: $bg_grey;
            z-index: 0;
            opacity: 0.7;
        }

        .text {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 50%;
            line-height: 120%;

            p {
                margin: 0;
                font-size: $small_font;
                @include phone {
                    // font-size: $small_font * 1.25;
                }
            }

            .title {
                color: $green;
                text-decoration: none;
                font-weight: 500;
                font-size: $font_size;
                line-height: 120%;
                margin-bottom: 5px;
                @include phone {
                    font-size: $font-size * 1.25;
                }
            }
        }

        &:hover {
            .title {
                text-decoration: underline;
            }

            .image {
                @include no-phone {
                    top: 20px;
                }
            }

            // }
        }

        .image {
            // background: blue;#

            // z-index: 0;
            position: absolute;
            top: 30px;
            right: -15%;
            width: 70%;
            height: 150%;
            background: transparent url('../img/products/schussler.png')
                no-repeat top/contain;
            transition: top 0.5s ease;
        }
    }
}
