// Variables
@import './utilities/variables';

.team_member_wrapper {
	width: 75%;
	position: relative;
	margin: 100px auto;
	max-width: 1000px;
	font-size: 18px;

	@include phone {
		width: 100%;
		position: relative;
	}
	.team_member {
		width: 75%;
		max-width: $max_width;
		margin: 0 auto;
		position: relative;
		.image {
			width: 35%;
			padding-bottom: 35%;
			border-radius: 100%;
			background-size: cover;
			background-position: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			@include phone {
				width: 70%;
				padding-bottom: 70%;
				margin-left: 15%;
				position: relative;
				transform: none;
			}
		}
		.info {
			width: 60%;
			@include phone {
				width: 100%;
				// padding-bottom: 100%;
				position: relative;
				h2 {
					text-align: center;
					font-size: $h1_font_size;
				}
				.description {
					text-align: justify;
					word-wrap: break-word;
					-webkit-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
				}
			}
		}
	}
	.team_member.image_left {
		.image {
			left: 0;
		}
		.info {
			float: right;
		}
	}
	.team_member.image_right {
		.image {
			right: 0;
		}
		.info {
			float: left;
		}
	}
}
