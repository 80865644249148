@import './utilities/variables';

.product_preview {
  background-image: url('../img/products/granules_tube_4g.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @include phone {
    display: none;
  }

  &.granules {
    background-image: url('../img/products/granules_tube_4g.png');

    &.bottle_10g {
      background-image: url('../img/products/granules_bottle_10g.png');
    }

    &.bottle_15g {
      background-image: url('../img/products/granules_bottle_15g.png');
    }
  }

  &.drops {
    background-image: url('../img/products/drops_30ml.png');

    &.bottle_60ml {
      background-image: url('../img/products/drops_60ml.png');
    }

    &.bottle_125ml {
      background-image: url('../img/products/drops_125ml.png');
    }

    &.bottle_250ml {
      background-image: url('../img/products/drops_250ml.png');
    }
  }

  &.globuli {
    background-image: url('../img/products/tube_1g.png');

    &.tube_1g {
      background-image: url('../img/products/tube_1g.png');
    }

    &.bottle_10g {
      background-image: url('../img/products/globuli_bottle_10g.png');
    }

    &.bottle_15g {
      background-image: url('../img/products/globuli_bottle_15g.png');
    }
  }

  &.creme {
    background-image: url('../img/products/creme_50g.png');
  }

  &.trituration {
    background-image: url('../img/products/trituration_60g.png');
  }

  &.suppository {
    background-image: url('../img/products/suppository_kids.png');

    &.twelve_pieces_adults {
      background-image: url('../img/products/suppository_adults.png');
    }
  }
}
