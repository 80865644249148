@import './utilities/variables';

select {
  appearance: none;
  background: none;
}

input:not([type='checkbox']),
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $green;
  display: block;
  width: 100%;
  margin: 10px 0;
  line-height: 150%;
  padding: 12px;
  border-radius: 3px;
  // color: $green;
  border: 1px solid $green;
  font-size: 15px;
  @include phone {
    font-size: 16px;
  }
  box-sizing: border-box;
  &::placeholder {
    color: $green;
  }
  &:focus {
    outline: none;
    border: 2px solid $green;
    padding: 11px;
    color: $green;
  }
  &[hidden] {
    display: none;
  }
}
form label {
  color: $green;
}
.error input {
  color: $red;
  border: solid 2px $red;
  padding: 11px;
  &::placeholder {
    color: $red;
  }
  &:focus {
    color: $green;
    border: solid 2px $green;
    padding: 11px;
    &::placeholder {
      color: $green;
    }
  }
}

.ui.grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 10px 0;
  .column {
    display: flex-box;
    margin: 0 5px;
    flex: 1;
    input,
    select {
      margin: 0;
    }
    .field {
      margin: 0;
    }
    select {
      height: 100%;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

form .loading {
  // visibility: hidden;
}

.singup_form {
  .only_professional {
    color: $red;
    border: 2px solid $red;
    padding: 15px;
    box-sizing: border-box;
  }
  .shipping_address {
    max-height: 0;
    transition: max-height 1s linear, opacity 0.5s ease;
    overflow: hidden;
    opacity: 0;
    &.open {
      max-height: 1000px;
      opacity: 1;
      transition: max-height 1s linear, opacity 0s;
    }
  }
  .file_name {
    color: $green;
    text-align: center;
  }

  .upload_button {
    position: relative;
    max-width: 100%;
    &.error {
      color: $white;
      background: $red;
      border-color: $red;
    }
    .hidden {
      position: relative;
      opacity: 0;
      word-break: break-word;
    }
    .visible {
      position: absolute;
      // left: 50%;
      left: 0;
      top: 50%;

      transform: translate3d(0, -50%, 0);
      text-align: center;
      display: block;
      width: 100%;
      word-break: break-word;
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }

  .loading {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0.9;
    display: none;
    border-radius: 5px;
    span {
      position: absolute;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
      display: block;
      width: 100%;
      color: $green;
    }
  }
  &.loading .loading {
    display: block;
  }
}

/*Checkboxes styles*/
input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px 'Open Sans', Arial, sans-serif;
  color: $primary;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type='checkbox'].white + label,
input[type='checkbox'].react-cookie-law-option-checkbox + label {
  color: $white;
}

input[type='checkbox'] + label:last-child {
  margin-bottom: 0;
}

input[type='checkbox'] + label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid $primary;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type='checkbox'].white + label:before,
input[type='checkbox'].react-cookie-law-option-checkbox + label:before {
  border: 1px solid $white;
}

input[type='checkbox']:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  border-width: 2px;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form_holder {
  position: relative;

  form {
    transition: opacity 1s ease;
  }

  .form_overlay {
    visibility: hidden;
    //     opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //     background: $primary;
  }

  .loading_holder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%) translateY(20px);
    // background: blue;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, transform 0.5s ease;
    text-align: center;

    .circle-loader {
      position: inline-block;
      // left: 50%;
      // transform: translateX(-50%);
    }

    .messages {
      position: relative;
      text-align: center;
      height: 50px;
      margin-top: 20px;

      .loading_message,
      .success_message {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        // left: 50%;
        text-align: center;
        color: $white;
        transform: translate(0, -50%);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.background_white {
  .form_holder {
    .messages {
      .loading_message,
      .success_message {
        color: $primary;
      }
    }
  }
}

.form_holder.loading {
  form {
    opacity: 0;
  }

  .form_overlay {
    visibility: visible;
    // opacity: .5;
  }

  .loading_holder {
    opacity: 1;
    visibility: visible;

    transform: translate(-50%, -50%) translateY(0px);

    .loading_message {
      visibility: visible;
      opacity: 1;
    }
  }
}

.form_holder.success {
  .form_overlay {
    visibility: visible;
    // opacity: .5;
  }

  form {
    opacity: 0;
  }

  .loading_holder {
    opacity: 1;
    visibility: visible;

    transform: translate(-50%, -50%) translateY(0px);

    .success_message {
      visibility: visible;
      opacity: 1;
    }
  }
}

.field {
  position: relative;
  // padding: 1px;
  // margin: 20px 0;
  margin: 10px 0 0 0;
  // transition: padding 0.5s ease;

  .error_msg {
    // color: $primary;
    color: $red;
    position: relative;
    top: 0;
    font-size: 15px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.5s 0s ease, margin 0.5s ease;
    margin-bottom: 0px;
    display: block;
  }

  .required {
    position: relative;
    display: block;
  }

  & .required::after {
    content: '!';
    display: block;
    position: absolute;
    font-weight: bold;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    color: $white;
    opacity: 0;
    transition: opacity 0.5s 0s ease, right 0s 2s ease;

    // width: 10px;
    // height: 10px;
    // background: red;
  }

  &.error {
    // padding-top: 25px;
    font-weight: bold;
    color: $red;

    label {
      font-weight: bold;
      color: $red;
    }

    select {
      border: 1px solid $red;
      font-weight: bold;
      color: $red;
    }

    .error_msg {
      opacity: 1;
      margin-bottom: 20px;
      transition: opacity 0.5s 0s ease, margin 0.5s ease;
    }

    & .required::after {
      opacity: 1;
      right: 20px;
      transition: opacity 0.5s 0.5s ease, right 0.5s 0.5s ease;
    }
  }
}

.background_color {
  .field {
    .error_msg {
      color: white;
    }
  }
}
