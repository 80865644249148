// Variables
@import './utilities/variables';

.single_homeopathy_display_wrapper {
    .section button .icon {
        position: relative;
        margin: 0 auto;
        // background: red;
    }
}

.remedy_display_wrapper {
    position: relative;

    .remedy_display {
        position: relative;
        width: 50%;
        max-width: $max-width;
        margin: 0 auto;
        @include phone {
            width: 80%;
            // left: 10%;
        }

        // background: #eee;
        h2 {
            text-align: center;
        }

        .searchStr {
            position: relative;
            width: 60%;
            left: 20%;
            @include phone {
                width: 100%;
                left: 0%;
            }
        }

        button.show_all {
            display: inline;
            // width: 60%;
            right: 20%;
            text-align: right;
            position: absolute;
            @include phone {
                right: 0%;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .remedies {
            position: relative;
            width: 60%;
            left: 20%;
            margin-top: 30px;
            @include phone {
                left: 0;
                width: 100%;
                margin-top: 50px;
            }

            button.remedy {
                padding: 5px 10px;
                transition: background 0s ease;
                cursor: pointer;
                display: block;
                border: none;
                color: $grey;
                width: 100%;
                border-radius: 0;
                text-align: left;
                @include phone {
                    font-size: 100%;
                    line-height: 200%;
                    padding: 10px 0;
                }

                &:hover {
                    background: $bg_grey;
                    text-decoration: underline;
                    color: $green;
                }
            }
        }

        .size_selector {
            width: 60%;
            left: 20%;
            position: relative;
            display: flex;
            justify-content: space-between;
            @include phone {
                left: 0%;
                width: 100%;
            }

            button {
                min-width: 70px;
                background: none;
                border: none;
                color: $grey;
                padding: 10px 15px;
                font-size: 12px;
                -webkit-tap-highlight-color: transparent;
                &:nth-child(2) {
                    padding-left: 0;
                }

                .icon {
                    opacity: 1;
                    transition: opacity 0.5s ease;

                    &.active {
                        opacity: 0;
                    }
                }

                &:hover:not(.disabled),
                &.active {
                    color: $green;

                    .icon {
                        opacity: 0;

                        &.active {
                            opacity: 1;
                        }
                    }
                }

                &.disabled {
                    opacity: 0.3;

                    &:hover {
                        border: 0;
                    }
                }

                .icon_holder {
                    left: 50%;
                    width: 100%;
                    height: 35px;
                    margin-bottom: 10px;
                    position: relative;
                    transform: translate(-50%, 0);

                    .icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                span {
                    // padding: 0 5px;
                }
            }

            .icon_holder {
                left: 50%;
                width: 100%;
                height: 35px;
                margin-bottom: 10px;
                position: relative;
                transform: translate(-50%, 0);

                .icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }

            // button {
            //     // width: 20%;
            //     position: relative;

            //     span {
            //         // position: absolute;
            //         // top: 50%;
            //         // left: 50%;
            //         // transform: translate3d(-50%, -50%);
            //     }
            // }
        }

        .add_to_cart {
            width: 60%;
            left: 20%;
            position: relative;
            text-align: center;

            .price {
                display: block;
                text-align: center;
                margin: 20px 0;
                color: $primary;
            }

            .quantity_field {
                margin: 10px auto;
                @include phone {
                    width: 61%;
                }
                // display: inline-block;
                // margin-right: 10px;
            }

            button.buy {
                position: relative;
                display: inline-block;
                @include phone {
                    width: 100%;
                }
            }
        }
    }
}

.icon.icon_background {
    width: 30%;
    padding-bottom: 30%;
    position: absolute;
    opacity: 0.1;
    // background: red;
    left: -10%;
    top: -50px;
    z-index: -1;
}
