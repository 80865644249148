// Variables
@import './utilities/variables';

.mobile_menu {
  // width: 100%;
  // height: 100%;
  -webkit-tap-highlight-color: none;

  @include desktop {
    display: none;
  }

  .mobile_header {
    z-index: 1501;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background: $trans_white;

    &.menu_open {
      background: transparent;
      width: 50px;

      .mobile_logo {
        visibility: hidden;
      }

      .phone {
        visibility: hidden;
      }
    }

    .phone {
      display: block;
      position: absolute;
      padding: 5px;
      top: 0px;
      width: 40px;
      left: 5px;
      height: 40px;

      .icon {
        width: 100%;
        height: 100%;
        background: url('../img/icons/icon_phone_active.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    .mobile_logo {
      top: 3px;
      width: 50%;
      left: 25%;
      height: 44px;
      background: url('../img/logo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      // @include phone {
      position: absolute;
      width: 50%;
      // }

      // @include tablet {
      //     position: fixed;
      //     width: 200px;
      //     height: 50px;
      //     padding-bottom: 0;
      // }

      @include desktop {
        position: fixed;
        width: 200px;
        height: 50px;
        padding-bottom: 0;
      }
    }

    .mobile_menu_button {
      width: 50px;
      height: 50px;
      // background: $primary;

      position: fixed;
      top: 0;
      right: 0;
      padding: 10px;
      margin: 0;
      border-radius: 0;
      border: none;

      &:hover,
      &:active {
        background: none;
      }

      .bar_holder {
        position: relative;
        width: 100%;
        height: 100%;

        .bar {
          background: $primary;
          position: absolute;
          height: 10%;
          width: 100%;
          transition: opacity 0.5s ease, transform 0.5s ease;

          &:nth-child(1) {
            top: 0;
            transform-origin: 0 0;
          }

          &:nth-child(2) {
            top: 45%;
          }

          &:nth-child(3) {
            top: 90%;
            transform-origin: 0% 100%;
          }
        }
      }
    }

    &.menu_open .mobile_menu_button .bar {
      background: white;
    }
  }

  .mobile_menu_overlay {
    z-index: 1500;
    width: 100%;
    height: 100%;
    background: $primary;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.5s, opacity 0.5s ease;

    .link_holder {
      position: absolute;
      top: 50%;
      left: 0%;
      width: 100%;
      transform: translate3d(0, -50%, 0);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
        visibility 0s;
    }

    .menu_entry,
    .menu_entry:hover,
    .menu_entry:active {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
      color: $white;
      background: none;
      border: 0px;
      width: 100%;
      font-size: 3vh;
      // height: 5.5vh;
      // position: absolute;
      position: relative;
      display: block;
      text-align: left;
      padding: 1.3vh 10%;
      letter-spacing: 0.5px;
      // font-family: Helvetica Neue, IBM Plex Sans;
      font-weight: 400;

      span {
        position: relative;
        display: block;
        top: 0;
        opacity: 0;
        transform: translate(-20px, 0);
        transition: opacity 0.5s ease, transform 0.5s ease;
      }

      .icon {
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 10%;
        width: 6vh;
        height: 6vh;
        transform: translateY(-50%);
      }
    }

    &.subpage_active {
      .link_holder {
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
          visibility 0s 0.5s;
        transform: translate3d(-50%, -50%, 0);
        opacity: 0;
        visibility: hidden;
      }
    }

    .subpage {
      position: absolute;
      transform: translate3d(50%, 0, 0);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
        visibility 0s 0.5s;
      visibility: hidden;
      width: 100%;
      height: 100%;
      opacity: 0;

      .links {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0%;
        width: 100%;
      }

      &.active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
          visibility 0s;
      }
    }

    .back,
    .back:hover,
    .back:active {
      -webkit-tap-highlight-color: transparent;
      background: none;
      color: $white;
      border: 0px;
      // width: 100%;
      font-size: 3.5vh;
      position: absolute;
      top: 30px;
      left: 20px;
      padding-left: 50px;

      .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 40px;
        height: 50px;
      }
    }

    .imprint {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 15px;
    }
  }

  &.open {
    .mobile_menu_overlay {
      opacity: 1;
      visibility: visible;
      transition: visibility 0s, opacity 0.5s ease;

      .menu_entry {
        span {
          opacity: 1;
          transform: translate(0, 0);
        }

        .icon {
          transition: opacity 1s ease-in-out;
          opacity: 1;
        }

        &:nth-child(1) .child {
          transition-delay: 0s;
        }

        &:nth-child(2) .child {
          transition-delay: 0.2s;
        }

        &:nth-child(3) .child {
          transition-delay: 0.4s;
        }

        &:nth-child(4) .child {
          transition-delay: 0.6s;
        }

        &:nth-child(5) .child {
          transition-delay: 0.8s;
        }

        &:nth-child(6) .child {
          transition-delay: 1s;
        }

        &:nth-child(7) .child {
          transition-delay: 1.2s;
        }

        &:nth-child(8) .child {
          transition-delay: 1.4s;
        }

        &:nth-child(9) .child {
          transition-delay: 1.6s;
        }

        &:nth-child(10) .child {
          transition-delay: 1.8s;
        }

        &:nth-child(11) .child {
          transition-delay: 2s;
        }
      }
    }

    .mobile_menu_button {
      .bar {
        background: $white;

        &:nth-child(1) {
          top: 0;
          transform: rotate(45deg);
          width: 130%;
        }

        &:nth-child(2) {
          top: 45%;
          opacity: 0;
        }

        &:nth-child(3) {
          top: 90%;
          transform: rotate(-45deg);
          width: 130%;
        }
      }
    }
  }
}
