// Variables
@import './utilities/variables';

.horizontal_menu_wrapper {
    width: 100%;
    background: $bg_grey;
    position: 'relative';

    .horizontal_menu {
        width: $max_width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @include no-desktop {
            width: 100%;
            flex-wrap: wrap;
        }

        .column {
            width: 22%;
            float: left;
            margin: 0 1.5%;
            text-align: center;
            position: relative;
            color: $green;
            padding-bottom: 65px;

            @include no-desktop {
                width: 45%;
                margin: 10px 2.5%;
                // background: red;
                align-items: stretch;
            }

            .icon {
                margin: 30px auto 0 auto;
                height: 40px;
            }

            h2 {
                font-size: 22px;
                font-weight: 300;
            }

            .button {
                position: absolute;
                bottom: 35px;
                left: 50%;
                transform: translateX(-50%);
                background: none;
                border: solid 1px $green;
                color: $green;
                padding: 10px 20px;
                cursor: pointer;
                width: 50%;
                transition: background $button_transition_time ease,
                    color $button_transition_time ease;

                @include no-desktop {
                    position: absolute;
                    left: 10%;
                    width: 80%;
                    transform: none;
                    box-sizing: border-box;
                    bottom: 0;
                    color: $white;
                    background: $green;
                }

                &:hover {
                    background: $green;
                    color: $white;
                }
            }
        }
    }
}
